import React, { useState } from 'react';
import { CircularProgress, Paper } from '@material-ui/core';
import { AllInclusive,/*, Category, Class*/ 
Search} from '@material-ui/icons';
import { Button, Dropdown, DropdownButton, FormControl, InputGroup, Modal, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { professionalsList } from '../../global/action';
import Axios from 'axios';
import { PROXMIN } from '../../global/constants';
import { head } from '../../global/gadget/gadget';


const Filter=({category, setProfessionals})=>{


  // const [cat, setCat] = useState(null);
  const [name, setName]  = useState('');
  // const [catSub, setCatSub]  = useState(null);
  const [fetching, setFecthing] = useState(false);
  const [show, setShow] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  

  const getProfessionals=(e)=>{
    if(e === '4'){
      setShow(true);
    }    
  }

  const collectName=(e)=>{
    setName(e.target.value)
  }

  const getProfessionalByName=()=>{
    if(fetching || !name.trim()) return;
    Axios.get(`${PROXMIN}/get_professional_by_name/${name.trim()}` , {headers: head()})
    .then(res=>{
      // console.log(res.data.data);
      setProfessionals(res.data.data);
      setFecthing(false);
    })
    .catch(err=>{
      // console.log(err);
      setFecthing(false);
    })
  }

  const fetchAllProfessionals=()=>{
    if(fetching) return;
    Axios.get(`${PROXMIN}/get_all_professionals` , {headers: head()})
    .then(res=>{
      // console.log(res.data.data);
      setProfessionals(res.data.data);
      setFecthing(false);
    })
    .catch(err=>{
      // console.log(err);
      setFecthing(false);
    })
  }
  const getProfessionalByCategory=(cat)=>{
    if(fetching || !cat) return;
    setFecthing(true);
    Axios.get(`${PROXMIN}/get_professional_by_category/${cat}`, {headers: head()})
    .then(res=>{
      if(res.data.success){
        setProfessionals(res.data.data);
        setFecthing(true);
      }
    })
    .catch(err=>{
      setFecthing(true);

    })
  }
  const getProfessionalBySubcategory=(cat, sub)=>{
    if(fetching || !cat || !sub) return;
    setFecthing(true);
    setFetchError(false);
    Axios.get(`${PROXMIN}/get_professional_by_subcategory/${cat}/${sub}`, {headers: head()})
    .then(res=>{
      if(res.data.success){
        setProfessionals(res.data.data);
        setShow(false);
        setFecthing(false)
      }
    })
    .catch(err=>{
      setFecthing(false);
      setFetchError(true);
    })
  }
  return(<div>
    <Paper variant='outlined'> 
      <Nav style={{width:'100%'}} fill variant='tabs' onSelect={getProfessionals}>
        <Nav.Item>
          <Nav.Link eventKey={1} >
            <AllInclusive onClick={()=>{fetchAllProfessionals()}} style={{color:'#0751DB'}} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={2} >
            <InputGroup>        
            <FormControl id="inlineFormInputGroup" onChange={collectName} placeholder="Username" />
            <InputGroup.Append>
              <InputGroup.Text>
                <Search color='primary' onClick={()=>{getProfessionalByName()}} /> 
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={3}>            
            <DropdownButton id='dropdown-item-button-cat' title="Category">
              {category && category.map(data=>
                <Dropdown.Item as="button"  key={data.id}
                  onClick={()=>{getProfessionalByCategory(data.id)}}
                >
                  {data.name}
                </Dropdown.Item>
                
              )}
            </DropdownButton>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={4}>
            <Button variant='primary'>Subcategory</Button>
          </Nav.Link>
        </Nav.Item>
      </Nav> 
    </Paper>

    <div>
      {category?
      <Modal show={show}  onHide={()=>{setShow(false)}}>
        <Modal.Body >
          <div className='py-5 mt-5 d-flex justify-content-center text-danger'>
            <div>
              <div>{fetching && <CircularProgress size='mini' />}</div>
              <div>
                { category.map((data, index)=>
                  <DropdownButton key={data.id} id='dropdown-item-button-cat' title={data.name}>
                      {data.subcategory && data.subcategory.map(sub=>
                        <Dropdown.Item as="button"  key={data.id}
                          onClick={()=>{getProfessionalBySubcategory(data.id, sub.id)}}
                        >
                          {sub.name}
                        </Dropdown.Item>
                        
                      )}
                    </DropdownButton>
                )}
              </div>
              {fetchError &&
                <div className='d-flex justify-content-center text-danger'>
                  <small>Something went wrong</small>
                </div>
              }

            </div>
          </div>
        </Modal.Body>
      </Modal>:
      <div className='d-flex justify-content-center text-danger'>
          No category fetched
      </div>
      }
  </div>
  </div>)
}

// const mapStateToProps = (state) => {
//   return {
//     professionals: state.professionals,
//   }
// }

const mapDispatchToProps = (dispatch) => {
  return {
    setProfessionals: (payload)=>dispatch(professionalsList(payload)),
  }
}

export default connect(null, mapDispatchToProps) (Filter);



// const Subcategories=({category, show, setShow})=>{
//   return(<div>
//     <Modal show={show} onHide={()=>{setShow(false)}}>
//       <Modal.Body>
//         {category && category.map((data, index)=>
//           <DropdownButton key={data.id} id='dropdown-item-button-cat' title={data.name}>
//               {data.subcategory && data.subcategory.map(sub=>
//                 <Dropdown.Item as="button"  key={data.id}
//                   onClick={()=>{}}
//                 >
//                   {sub.name}
//                 </Dropdown.Item>
                
//               )}
//             </DropdownButton>
//         )}

//       </Modal.Body>
//     </Modal>
//   </div>)
// }
