import React, { useEffect, useState, useRef} from 'react';
import {CircularProgress, Paper } from '@material-ui/core';
import Axios from 'axios';
import { PROXMIN } from '../../global/constants';
import { head, LUX_ZONE } from '../../global/gadget/gadget';




const SingleClientDoneFiles=({id, user})=>{

  const mounted = useRef(true);
  console.log(user);
  
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [fileList, setFileList] = useState(null);
	const [fetchingMoreFiles, setFetchingMoreFiles] = useState(false);



	useEffect(()=>{
		const fetchClients=()=>{			
			Axios.get(`${PROXMIN}/professional_participant_done_files/${id}/${user}` , {headers: head()})
				.then(res=>{
					if(res.data.success){
            setFileList(res.data.data);
            setLoadingFiles(false);
          }
				})
				.catch(err=>{
          // console.log(err);
          if(!mounted.current) return;
					setLoadingFiles(false);
				})
		}

		if(!fileList && user) fetchClients();
		return ()=>{mounted.current = false}

	},[fileList, id, user]);

	useEffect(()=>{
		if(!fileList || !fileList.next_page_url || fetchingMoreFiles) return;
		/** This  methods fetches more clients as virtual scrolling*/
		const getMoreFiles=()=>{
			if(!fileList.next_page_url) return;
			setFetchingMoreFiles(true);
			Axios.get(fileList.next_page_url, {headers: head()})
			.then(res=>{
				if(res.data.success){
					res.data.data.data = [...fileList.data, ...res.data.data.data];
					setFileList(res.data.data);
					setFetchingMoreFiles(false);
				}
			})
			.catch(err=>{
        if(!mounted.current) return;
				setFetchingMoreFiles(false);
			})
		}

		/** The placeholder for event listerner */
		const virtualScrolling=()=>{
      // console.log(`${document.body.scrollHeight}/${window.pageYOffset}/${window.innerHeight}/
      // ${document.body.offsetHeight}/${document.body.clientHeight}`);
      if(window.pageYOffset+window.innerHeight >= document.body.offsetHeight){
        getMoreFiles();
      }
    }
    window.addEventListener('scroll', virtualScrolling);
  
    return()=>{
      window.removeEventListener('scroll', virtualScrolling)
    }
	});



  return(<div className='w3-animate-zoom'>
    <div className='row'>
			<div className='col-12 mb-1'>
				<Paper variant='outlined'  square
					className='d-flex justify-content-center py-1 mb-1 w3-light-grey'
					style={{marginLeft:'3px'}}		
				>	
					{loadingFiles?
						<div>
							<CircularProgress  />
						</div>:
						<strong> 
							{fileList && typeof fileList.total === 'number' && fileList.total?
								`Total: ${fileList.total}`:
								"No File fetched"
							}
						</strong>
					}	
				</Paper>
			</div>
		</div>
		<div>
			{fileList && fileList.data ?
				<div>
          {fileList.data.map((pro,index)=>
            <div  key={`${pro.id}-${index}`}
              className="mb-3 w3-animate-opacity px-sm-2 w3-third">
              <div className="w3-card w3-hover-shadow ">
                <a href={pro.message} target='_blank' rel='noopener noreferrer'>
                  <img alt='nill' src={pro.message} style={{width:'100%', height:'300px'}} />
                </a>
                <div className="w3-container mt-1 w3-center">
                  <div><small>{LUX_ZONE(pro.created_at)}</small></div>
                  <div className='d-flex justify-content-around'>
                      <small>Project: {pro.allocation_id}</small>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>:
				null
			}
			<div className='d-flex justify-content-center'>
				{fetchingMoreFiles && <CircularProgress style={{ fontSize: '5px' }} />}
			</div>
		</div>
  </div>)
}

export default SingleClientDoneFiles;

/*  */