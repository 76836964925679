import React, { useEffect, useState, useRef} from 'react';
import { CircularProgress, Paper } from '@material-ui/core';
import Axios from 'axios';
import { Dropdown, DropdownButton, Table } from 'react-bootstrap';
import { PROXMIN } from '../../global/constants';
import { head, LUX_ZONE } from '../../global/gadget/gadget';


const statuses = ['Unseen', 'Accepted', 'Completed', 'Rejected', 'Seen','Reassigned', 'Terminated']
const header = [ 'S/N', 'Username', 'Description' , 'status',  "Rated", 'Rating', 'StatusTime', 'timeLimit', 'Created_at', 'Options'];
const AllPRojects=({id, modify})=>{

  const mounted = useRef(true);
  const scrollBox = useRef(document.getElementById('scrollBox'));
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [projectList, setPRojectList] = useState(null);
  const [fetchingMoreProjects, setFectingMoreProjects] = useState(false);



	useEffect(()=>{
		const fetchPRojects=()=>{			
			Axios.get(`${PROXMIN}/professional_projects/${id}` , {headers: head()})
				.then(res=>{
          // if(!mounted.current) return;
          console.log(mounted.current, 'current');
          if(res.data.success){
            setPRojectList(res.data.data);
            setLoadingProjects(false);
          }
				})
				.catch(err=>{
          // console.log(err);
          if(!mounted.current) return;
					setLoadingProjects(false);
				})
		}

		if(!projectList) fetchPRojects();
		return ()=>{mounted.current = false}

	},[projectList, id]);

	useEffect(()=>{
		if(!projectList || !projectList.next_page_url || fetchingMoreProjects) return;
		/** This  methods fetches more clients as virtual scrolling*/
		const fetchMoreClients=()=>{
			if(!projectList.next_page_url) return;
			setFectingMoreProjects(true);
			Axios.get(projectList.next_page_url, {headers: head()})
			.then(res=>{
				if(res.data.success){
					res.data.data.data = [...projectList.data, ...res.data.data.data];
					setPRojectList(res.data.data);
					setFectingMoreProjects(false);
				}
			})
			.catch(err=>{
        if(!mounted.current) return;
				setFectingMoreProjects(false);
			})
		}

		/** The placeholder for event listerner */
		const virtualScroll =()=>{

			console.log(scrollBox.current.scrollTop, scrollBox.current.clientHeight, scrollBox.current.scrollHeight);
			if(scrollBox.current.scrollTop+scrollBox.current.clientHeight >= scrollBox.current.scrollHeight){
				fetchMoreClients();
			}
		}

		if(!scrollBox.current) scrollBox.current = document.getElementById('scrollBox');
		if(scrollBox.current) scrollBox.current.addEventListener('scroll', virtualScroll);
		return ()=>{if(scrollBox.current) scrollBox.current.removeEventListener('scroll',virtualScroll);}
  });
  




  return(<div className='w3-animate-zoom'>
    <div className='row'>
			<div className='col-12 mb-1'>
				<Paper variant='outlined'  square
					className='d-flex justify-content-center py-1 mb-1 w3-light-grey'
					style={{marginLeft:'3px'}}		
				>	
					{loadingProjects?
						<div>
							<CircularProgress />
						</div>:
						<strong> 
							{projectList && typeof projectList.total === 'number' && projectList.total?
								`Total: ${projectList.total}`:
								"No Project fetched"
							}
						</strong>
					}	
				</Paper>
			</div>
		</div>
		<div id="scrollBox" style={{ height:'74vh', overflowY:'auto', overflowX:'hidden'}}>
			{projectList && projectList.data ?
				<Table responsive striped hover size="sm">
					<thead>
						<tr>
							{header.map((data, index)=>
								<td key={index} >
									<strong>{data}</strong>
								</td>
							)}
						</tr>
					</thead>
					<tbody>
						{projectList.data.map((pro,index)=>
							<tr key={`${pro.userName}-${index}`}
								className='w3-hover-light-gray'
							>
								<td>{index+1}</td>
								<td>{pro.userName}</td>
								<td>{pro.description}</td>
                <td>{statuses[pro.status]}</td>
								<td>{pro.rated?'Yes':'No'}</td>
								<td>{pro.rate}</td>
								<td>{LUX_ZONE(pro.statusTime)}</td>
								<td>{pro.timeLimit}</td>
                <td>{LUX_ZONE(pro.created_at)}</td>
								<td>
                  <DropdownButton id={`dropdown-item-button-${index}}`} title="Options">
                    <Dropdown.Item as="button" 
                      onClick={()=>{
                        modify('singleClientProjects', pro.userName);
                      }}
                    >
                      User's Others
                    </Dropdown.Item>
                    <Dropdown.Item as="button"
                      onClick={()=>{
                        modify('projectChat', pro.id);
                      }}
                    >
                      Chats
                    </Dropdown.Item>
                    {/* <Dropdown.Item as="button">

                    </Dropdown.Item> */}
                  </DropdownButton>
                </td>
							</tr>
						)}
					</tbody>
				</Table>:
				null
			}
			<div className='d-flex justify-content-center'>
				{fetchingMoreProjects && <CircularProgress style={{ fontSize: '5px' }} />}
			</div>
		</div>
  </div>)
}

export default AllPRojects;