import React, { useEffect, useState, useRef} from 'react';
import { Avatar, CircularProgress, Paper } from '@material-ui/core';
import Axios from 'axios';
import { Dropdown, DropdownButton, Table } from 'react-bootstrap';
import { PROXMIN } from '../../global/constants';
import { head } from '../../global/gadget/gadget';
import { connect } from 'react-redux';
import { suspendedLoad } from '../../global/action';



const header = ['Picture', 'Suspect', 'Count' , 'UserName',  "Accumulated-point(s)", 'FirstName', 'LastName', 'Email', 'Options'];
const Clients=({id, modify, suspendedLoad, setSuspendedLoad})=>{

  const mounted = useRef(true);
  const scrollBox = useRef(document.getElementById('scrollBox'));

  
  const [loadingClients, setLoadingClients] = useState(true);
  const [clientList, setClientList] = useState(null);
	const [fetchinMorePros, setFetchingMorePros] = useState(false);



	useEffect(()=>{
		const fetchClients=()=>{			
			Axios.get(`${PROXMIN}/professional_client_count/${id}` , {headers: head()})
				.then(res=>{
					if(res.data.success){
            if(!mounted.current) return;
            setClientList(res.data.data);
            setLoadingClients(false);
          }
				})
				.catch(err=>{
          // console.log(err);
          if(!mounted.current) return;
					setLoadingClients(false);
				})
		}

		if(!clientList) fetchClients();
		return ()=>{mounted.current = false}

	},[clientList, id]);

	useEffect(()=>{
		if(!clientList || !clientList.next_page_url || fetchinMorePros) return;
		/** This  methods fetches more clients as virtual scrolling*/
		const fetchMoreClients=()=>{
			if(!clientList.next_page_url) return;
			setFetchingMorePros(true);
			Axios.get(clientList.next_page_url, {headers: head()})
			.then(res=>{
				if(res.data.success){
					res.data.data.data = [...clientList.data, ...res.data.data.data];
					setClientList(res.data.data);
					setFetchingMorePros(false);
				}
			})
			.catch(err=>{
        if(!mounted.current) return;
				setFetchingMorePros(false);
			})
		}

		/** The placeholder for event listerner */
		const virtualScroll =()=>{
			// console.log(scrollBox.current.scrollTop, scrollBox.current.clientHeight, scrollBox.current.scrollHeight);
			if(scrollBox.current.scrollTop+scrollBox.current.clientHeight >= scrollBox.current.scrollHeight){
				fetchMoreClients();
			}
		}

		if(!scrollBox.current) scrollBox.current = document.getElementById('scrollBox');
		if(scrollBox.current) scrollBox.current.addEventListener('scroll', virtualScroll);
		return ()=>{if(scrollBox.current) scrollBox.current.removeEventListener('scroll',virtualScroll);}
  });
  

  const processSuspect=(selectedId, user)=>{
    let cursor = document.getElementById(selectedId);
    if(cursor.checked){
      if(!suspendedLoad){
        setSuspendedLoad({pro: id, associates:[user]});
      }else{
        if(suspendedLoad.associates.indexOf(user) > -1) return;
        setSuspendedLoad({pro:id, associates: [...suspendedLoad.associates, user]})
      }
    }else{
      if(!suspendedLoad) return;
      let index = suspendedLoad.associates.indexOf(user)
      if( index < 0 ) return;
      let demo = {...suspendedLoad};
      demo.associates.splice(index, 1);
      setSuspendedLoad(demo);
    }
  }



  return(<div className='w3-animate-zoom'>
    <div className='row'>
			<div className='col-12 mb-1'>
				<Paper variant='outlined'  square
					className='d-flex justify-content-center py-1 mb-1 w3-light-grey'
					style={{marginLeft:'3px'}}		
				>	
					{loadingClients?
						<div>
							<CircularProgress  />
						</div>:
						<strong> 
							{clientList && typeof clientList.total === 'number' && clientList.total?
								`Total: ${clientList.total}`:
								"No Client fetched"
							}
						</strong>
					}	
				</Paper>
			</div>
		</div>
		<div id="scrollBox" style={{ height:'74vh', overflowY:'auto', overflowX:'hidden'}}>
			{clientList && clientList.data ?
				<Table responsive striped hover size="sm">
					<thead>
						<tr>
							{header.map((data, index)=>
								<td key={index} >
									<strong>{data}</strong>
								</td>
							)}
						</tr>
					</thead>
					<tbody>
						{clientList.data.map((pro,index)=>
							<tr key={`${pro.userName}-${index}`}
								className='w3-hover-light-gray'
							>
								<td>
								<Avatar 									
									src={pro.profilePic? pro.profilePic:'../../assets/avatar.png'} 
								/>
								</td>
                <td>
                  <input name={pro.userName} id={`${pro.userName}-${index}`} type='checkbox' 
                    onChange={()=>{processSuspect(`${pro.userName}-${index}`, pro.userName)}}
                    checked={(suspendedLoad && suspendedLoad.associates.indexOf(pro.userName) > -1)?
                      true:false
                    }
                  />
                </td>
                <td>{pro.count}</td>
								<td>{pro.userName}</td>
								<td>{pro.accumulated_points}</td>
								<td>{pro.firstName}</td>
								<td>{pro.lastName}</td>
								<td>{pro.email}</td>
								<td>
									{/* <Button style={{ background:'#6EE5B2' }} disableElevation>View</Button> */}
                  <DropdownButton id={`dropdown-item-button-${index}}`} title="Options">
                    <Dropdown.Item as="button" 
                      onClick={()=>{
                        modify('singleClientProjects', pro.userName);
                      }}
                    >
                      Projects
                    </Dropdown.Item>
                    <Dropdown.Item as="button"
                      onClick={()=>{
                        modify('singleClientDOneFiles', pro.maxId);
                      }}
                    >
                      Done Files
                    </Dropdown.Item>
                  </DropdownButton>
                </td>
							</tr>
						)}
					</tbody>
				</Table>:
				null
			}
			<div className='d-flex justify-content-center'>
				{fetchinMorePros && <CircularProgress style={{ fontSize: '5px' }} />}
			</div>
		</div>
  </div>)
}

const mapStateToProps = (state) => {
  return {
    suspendedLoad: state.suspendedLoad,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSuspendedLoad: (payload)=>dispatch(suspendedLoad(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (Clients);