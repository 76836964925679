import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import './App.css';
import Login from './components/auth/Login';
import Dashboard from './components/dashboard/Dashboard';
// import Professionals from './components/professionals/Professionals';
// import TableTest from './components/Table/TableTest';
import { adminInfo, intendedPage } from './global/action';
import { PROXY } from './global/constants';
import { head } from './global/gadget/gadget';

function App({admin, setAdmin, setIntendedPage}) {

  const mounted = React.useRef(true);

  const history = useHistory();
  const path = useLocation();

  const [loading, setLoading] = React.useState(true);

  
  React.useEffect(()=>{    
    if(path.pathname !== '/'){
        setIntendedPage(path.pathname);
    }

  },[path, setIntendedPage])

  React.useEffect(()=>{
    const checkAdmin =()=>{

      Axios.get(`${PROXY}/identify/XXADXX`, {headers: head()})
      .then(async(res)=>{
        if(!mounted.current) return;
        if(res.data.success){
          // console.log(JSON.parse(window.atob(res.data.data))[0]);
          await setAdmin(JSON.parse(window.atob(res.data.data))[0]);
          mounted.current && await setLoading(false);
        }else{
          if(res.data.message === 'xxx'){
            window.localStorage.clear();
            return history.replace('/');
          }
          mounted.current && await setLoading(false);
        }
      })
      .catch(err=>{
        mounted.current && setLoading(false);
        window.localStorage.clear();
        return history.replace('/');
      })
    }

    if(!admin){
      checkAdmin();
    }else{
      mounted.current && setLoading(false);
    }

    return ()=>{
      mounted.current = false;
    }
  },[admin, setAdmin, history]);



  return (<div>
    {loading?
      <div style={{ maringTop:'100px' }}>
         <div className='d-flex mt-5 justify-content-center'>
          <div className='w3-spin spinner w3-circle my-2'></div>
        </div>
      </div>:
      <div>
        {admin && admin.adminName?
         <Dashboard/>:
          <div style={{ height:'100%' }}>
            <Login />          
          </div>
        }
      </div>
    }
    
  </div>);
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAdmin: (payload)=>dispatch(adminInfo(payload)),
    setIntendedPage: (payload)=>dispatch(intendedPage(payload)),
  }
  
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
