import Echo from 'laravel-echo';
import { PUXY } from '../constants';
export const ESTIMATE_TIME=(SEC)=>{
  const sec = Math.abs(SEC);
  if(sec < 60) return  Math.floor(sec) +"sec";
  else if(sec >=60 && sec<120) return Math.floor(sec/60) +"min";
  else if(sec >=120 && sec <3600) return Math.floor(sec/60) +"mins";
  else if( sec >= 3600 && sec < 7200) return Math.floor(sec/3600) +"hr";
  else if(sec >= 7200 && sec < 86400) return  Math.floor(sec/3600) +"hrs";
  else if(sec >= 86400 && sec < 172800) return Math.floor(sec/86400) +"day";
  else if(sec >= 172800 && sec < 2592000) return  Math.floor(sec/86400) +"days";        
  else if(sec >= 259200&& sec < 5184000) return Math.floor(sec/2592000) +"month";    
  else if(sec >= 5184000&& sec < 31557600) return Math.floor(sec/2592000) +"months";    
  else if(sec >= 31557600 && sec < 63115200) return  Math.floor(sec/31557600 ) +"yr";    
  else if(sec >63115200) return Math.floor(sec/31557600 ) +"yrs";
}

export const CHAT_LIKE_TIME=(time)=>{
  if(!time) return '';
  if(!window.luxon) return '';
  const local = window.luxon.DateTime.local();
  const format = time.trim().replace(' ',"T")
  const ms = window.luxon.DateTime.fromISO(format, {zone: 'Africa/Accra'});
  const rezoned = ms.setZone(local.zoneName); 
  // const diff = Date.now()-Date.parse(rezoned);
  // let sec=((diff)/(1000));
  // sec = Math.abs(sec);
  const min= rezoned.minute.toString().length < 2 ? `0${rezoned.minute}`: `${rezoned.minute}`;
  const hour= rezoned.hour.toString().length < 2 ? `0${rezoned.hour}`: `${rezoned.hour}`;
  const day = rezoned.day;
  const monthName =window.luxon.Info.months('short')[rezoned.month-1]
  const today = new Date();
  if(today.getDate()=== day && today.getMonth() === (rezoned.month-1) && today.getFullYear() === rezoned.year) return   `${hour}:${min}`;
  else if(today.getDate()-day === 1 && today.getMonth() === (rezoned.month-1) && today.getFullYear() === rezoned.year) return `Yesterday ${hour}:${min}`;
  else return `${monthName} ${day}, ${rezoned.year}`;
}

export const scrollUp=(e)=>{
  const container = window.document.getElementById(e);
  if(!container) return;
  container.scrollIntoView(true);
}

export const LUX_ZONE=(time)=>{
  if(!time) return '';
  if(window.luxon){
      const local = window.luxon.DateTime.local();
      const format = time.trim().replace(' ',"T")
      const ms = window.luxon.DateTime.fromISO(format, {zone: 'Africa/Accra'});
      const rezoned = ms.setZone(local.zoneName);
      let dater = new Date(Date.parse(rezoned)).toString()
      dater = dater.slice(0,dater.indexOf('GMT'))
      return `${dater}`;
  }
  return '';
}

export const plainTimeZone=(time)=>{
  if(!time) return '';
  if(window.luxon){
      const local = window.luxon.DateTime.local();
      const format = time.trim().replace(' ',"T")
      const ms = window.luxon.DateTime.fromISO(format, {zone: 'Africa/Accra'});
      const rezoned = ms.setZone(local.zoneName);
      return new Date(Date.parse(rezoned));
  }
  return '';
}


export const generateFiveRandomNumbers=()=>{
  let numbers ="";
  for (let index = 0; index < 5; index++) {			
      numbers += Math.floor(Math.random() * 10)
  }
  return numbers;
}


export const ROUNDS =(value)=>{
  if(!value) return;
  try {
      let num = Number(value);
      if(isNaN(num)) return;
      if(num < 100) return `${num}`;
      else if(num > 99  && num < 1000) return `${Math.trunc(num/100)*100}+`;
      else if(num > 999  && num < 1000000) return `${Math.trunc(num/1000)*1000}K+`;
      else if(num > 999999  && num < 1000000000) return `${Math.trunc(num/1000000)*1000000}M+`;     
  } catch (e) {
   return;
  }
}

export const staticPusherConfig=()=>{
  window.Pusher =  require('pusher-js');
  window.Echo =  new Echo({
      broadcaster: 'pusher',
      key: 'b7c25b6dbdf8417de444',
      cluster: 'eu',
      forceTLS: true,
      authEndpoint: `${PUXY}broadcasting/auth`,
      auth:{headers:head()}
  });
}

// export const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xMjcuMC4wLjE6ODAwMFwvYXBpXC9hZG1pbl9sb2dpbiIsImlhdCI6MTYwMTE0NjE3MSwiZXhwIjoxNjMyNzAzNzcxLCJuYmYiOjE2MDExNDYxNzEsImp0aSI6Im9PdVVEYmZKQVVUZFBQZ28iLCJzdWIiOjEsInBydiI6ImNmMjg0YzJiMWUwNmYzM2MyNmJkNTc5NzU2NmQ5ZmQ3NGJlMTFiZjUifQ.wklp1WBOj7l2qrYRIXsDI1QNuRkAgWsjnk2iXujZcQo ";

// export const participant_token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xMjcuMC4wLjE6ODAwMFwvYXBpXC9sb2dpbiIsImlhdCI6MTYwNTg3NDEwMCwiZXhwIjoxNjM3NDMxNzAwLCJuYmYiOjE2MDU4NzQxMDAsImp0aSI6InBYb1NudGxzdmc3OUJ2UWoiLCJzdWIiOjEsInBydiI6IjAxNjlmYWZhYjM1MmQ3NTQzY2U1YjBhMzlhNDFiNmRjZGJkYTIyNDgifQ.mPWHczx4LY_KFJ1eD_mp-SwpN0b8CgaLHPCyaLMEwJo"
/** Authorization header */
export const head=()=>{
  let token;
  try {
    token = window.atob(window.atob(window.localStorage.MinToken));
  } catch (e) {
    return false;
  }
  return {Authorization: `Bearer ${token}`};
  // return {Authorization: `Bearer ${window.atob(window.atob(window.localStorage.MinToken))}`};
}
