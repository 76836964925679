import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
import { adminInfo } from '../../global/action';
import { connect } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Axios from 'axios';
import { PROXY } from '../../global/constants';
import { Link, useHistory } from 'react-router-dom';


const Login=({goPage, setAdmin})=>{

	const mounted = React.useRef(true);
	const formRef = React.useRef('form');

	let history = useHistory();


	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [submiting, setSubmiting] = React.useState(false);
	// const submiting = React.useRef(true);
	const [wentWrong, setWentWrong] = React.useState(false);
	const [notFound, setNotFound] = React.useState(false);



	const submit=async(e)=>{
		e.preventDefault();
		if(submiting.current) return;
		if(!/\w+@\w+\.\w/.test(email)) return;
		if(!/^[_a-zA-Z0-9]{8,}$/.test(password)) return;
		await setSubmiting(true);
		wentWrong && setWentWrong(false);
		notFound && setNotFound(false);
		const form = new FormData();
		form.append('email', email);
		form.append('password', password);
		Axios.post(`${PROXY}/admin_login`, form)
		.then(async(res)=>{
			if(res.data.success){
				if(!mounted.current) return;
				localStorage.MinToken = await  window.btoa(window.btoa(res.data.token))
				await setAdmin(JSON.parse(window.atob(res.data.data)))
				if(mounted.current) await setSubmiting(false);
				if(goPage !== '/'){
					history.push(goPage);
				}
			}else{
				if(res.data.ValidationError){
					window.localStorage.clear();
					mounted.current && await setNotFound(true);
				}else if(res.data.message){
					mounted.current && await setNotFound(true);
				}
				if(mounted.current) await setSubmiting(false);;
			}
		})
		.catch(err=>{
			if(mounted.current) setSubmiting(false);;
			mounted.current && setWentWrong(true);
		})

	}

	React.useEffect(()=>{

		return ()=>{
			mounted.current = false;
		}
	},[mounted])

	const handleChange=(e)=>{
		e.preventDefault();
		const incoming = e.target;
		if(incoming.name === 'email'){
				setEmail(e.target.value);
		}else{
			setPassword(e.target.value);
		}
		
	}


	return (<div className="container">
		<div style={{height: '90vh'}} className="row align-items-center">
			<div className="col-sm-12 col-md-3 col-lg-4 col-xl-4"></div>
			<div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
				<Card>
					<CardContent className="card-body">
						<Typography variant="h6" className="text-center mb-3" color="primary">
							Welcome Back
						</Typography>

						<ValidatorForm
							ref={formRef}
							onSubmit={submit}
						>
							<div className='d-flex my-1 justify-content-center'>
								<TextValidator 
									label='Login ID'
									value ={email}
									name='email'
									onChange={handleChange}
									validators={['required', 'isEmail' ]}
									errorMessages={['this field is required', 'email adress is expected']}
								/> 
							</div>
							<br/>
							<div className='d-flex my-1 justify-content-center'>
								<TextValidator 
									label='Pass Key'
									name='password'
									type='password'
									value={password}
									onChange={handleChange}
									validators={['required', 'matchRegexp:^[_a-zA-Z0-9]{8,}$']}
									errorMessages={['this field is required', 'minimum of 8 characters expected']}
								/>
							</div>
							
							 <br/>

							{notFound &&
								<div className='text-danger d-flex mb-2 justify-content-center'>
									<small>
										Incorrect password or ID
									</small>
								</div>
							}
							{wentWrong &&
								<div className='text-danger d-flex my-1 justify-content-center'>
									<small>
										Oops, something went wrong
									</small>
								</div>
							}
							{submiting &&
								<div className='d-flex my-1 justify-content-center'>
									<div className='w3-spin spinner w3-circle my-2'></div>
								</div>
							}

							<div className='d-flex justify-content-center mb-2'>
								<button disabled={submiting} className='w3-indigo w3-btn w3-round-xxlarge' type='submit'>Submit</button>
							</div>

						</ValidatorForm>

						
						<Typography className="text-center" variant="subtitle2">
							 Forgot password? <Link to="#">Recover Password</Link>
						</Typography>
					</CardContent>
				</Card>
			</div>
			<div className="col-sm-12 col-md-3 col-lg-4 col-xl-4"></div>
		</div>
	</div>)

}

const mapStateToProps = (state) => {
	return {
		goPage: state.intendedPage,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAdmin:(payload)=>dispatch(adminInfo(payload)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);



/*
<div className="form-group">
	<TextField id="loginID" label="Login ID" fullWidth defaultValue="123456789" variant="outlined" />
</div>
<div className="form-group mt-4">
	<TextField id="password" label="Password" fullWidth defaultValue="password" variant="outlined" />
</div>
<div className="form-group text-center">
	<Button variant="contained" fullWidth color="primary">Submit</Button>
</div>
*/