import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';


class ChatNotFound extends Component {
    state = {

    }

    render() {
        
        return (
            <div className="card" style={{ height: '87vh' }}>
            	<div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <Typography variant="body1" className="mb-4">Select a chat to see their message...</Typography>
                    <div className="chatNotFoundBg"></div>
                </div>
            </div>
        );
    }
}

export default ChatNotFound;