import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import { Typography, CircularProgress, Paper, /*Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody*/ } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Axios from 'axios';
import { PROXMIN } from '../../global/constants';
import { head, LUX_ZONE } from '../../global/gadget/gadget';

const useStyles = makeStyles((theme) => ({
	expand: {
	   transform: 'rotate(0deg)',
	   marginLeft: 'auto',
	   transition: theme.transitions.create('transform', {
	      duration: theme.transitions.duration.shortest,
	   }),
  	},
  	expandOpen: {
   	transform: 'rotate(180deg)',
  	},
}));

const category = ['Not accepted', 'Not rated', 'No Response', 'Unclear'];
// const header = ['Picture', 'userName', "Category", 'Report', 'Date', 'View']

const ReportedProjects =()=>{
	const classes = useStyles();

	const mounted = React.useRef(true);

	const [reports, setReports] = React.useState(null);
	const [loadinReports, setLoadinReports] = React.useState(true);
	const [gettingReports, setGettingReports] = React.useState(false);
	const [expanded, setExpanded] = React.useState(false);
	const [indexToExpand, setIndexToExpand] = React.useState(false);

	const handleExpandClick = (index) => {
		setIndexToExpand(index);
   	setExpanded(!expanded);
  	};

	React.useEffect(()=>{
		const fetchReports=()=>{	
			// console.log(head(), 'from reports');		
			Axios.get(`${PROXMIN}/get_reports` , {headers: head()})
				.then(res=>{
					// console.log(res.data.data);
					setReports(res.data.data);
					setLoadinReports(false);
				})
				.catch(err=>{
					// console.log(err);
					setLoadinReports(false);
				})
		}

		if(!reports) fetchReports();
		return ()=>{mounted.current = false}

	},[reports]);

	React.useEffect(()=>{
		if(!reports || !reports.next_page_url) return;
		/** This  methods fetches more professionals as virtual scrolling*/
		const fetchMoreReports=()=>{
			if(!reports.next_page_url) return;
			setGettingReports(true);			
			Axios.get(reports.next_page_url, {headers: head()})
			.then(res=>{
				if(res.data.success){
					res.data.data.data = [...reports.data, ...res.data.data.data];
					setReports(res.data.data);
					setGettingReports(false);
				}
			})
			.catch(err=>{
				setGettingReports(false);
			})
		}

		/** The placeholder for event listerner */
		const virtualScroll =()=>{
			if(window.pageYOffset+window.innerHeight >= document.body.offsetHeight){
				fetchMoreReports();
			}
		};

		window.addEventListener('scroll', virtualScroll);
		return ()=>{ window.removeEventListener('scroll',virtualScroll);}
	},[reports]);

	const encode=(data)=>{
		return window.btoa(data);
	}
		return (<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="d-flex justify-content-between mb-3">
							<h4>Reported Projects</h4>
							<div className="d-flex align-content-center">
								<div className="mr-2">
									<input type="search" className="form-control" placeholder="search" />
								</div>
								<div>
									<select className="custom-select">
										<option>Filter</option>
										<option>Name</option>
										<option>Date</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col-12 mb-1'>
						<Paper variant='outlined'  square
							className='d-flex justify-content-center py-1 mb-1 w3-light-grey'
							style={{marginLeft:'3px'}}		
						>	
							{loadinReports?
							<div>
								<CircularProgress fontSize='small' />
							</div>:
								<b> 
									{reports && typeof reports.total === 'number' && reports.total?
										`Total: ${reports.total}`:
										"No professional fetched"
									}
								</b>

						}
						</Paper>
					</div>
				</div>

				<div className="row">
					<div className="col-sm-12">
						<input type="search" className="form-control form-control-sm rounded-pill mb-2" placeholder="Search reports" />

						<div>
							{reports && reports.data?
							<div>
							{/*<TableContainer component={Paper}>
								<Table style={{ minWidth: 650 }} size="small" aria-label='simple table'>
									<TableHead className='w3-indigo'>
										<TableRow>
											{header.map((data, index)=>
												<TableCell key={index} style={{ color:'white' }}>
													{data}
												</TableCell>
											)}
										</TableRow>
									</TableHead>
									<TableBody>
										{reports.data.map((item,index)=>
											<TableRow key={`${item.userName}-${index}`}
												className='w3-hover-light-gray'
											>
												<TableCell>
												<Avatar 									
													src={item.profilePic? item.profilePic:'../../assets/avatar.png'} 
												/>
												</TableCell>
												<TableCell>{item.userName}</TableCell>
												<TableCell>{category[item.key-1]}</TableCell>
												<TableCell>{item.report.substring(0,40)}</TableCell>
												<TableCell>{LUX_ZONE(item.created_at)}</TableCell>
												<TableCell>
													<Link to="/reported-project-chat"><Button variant="contained" disableElevation>View</Button></Link>
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>*/}
								<div className="row">
								{ reports.data.map((item, index) => (
									<div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-3" key={`${item.userName}-${index}`}>
										<Card>
											<CardContent>
												<div className="flex-fill d-flex align-items-center my-2">
													<Avatar className="mr-2" 										
														src={item.profilePic? item.profilePic:'../../assets/avatar.png'} 
													/>
													<div className="d-flex flex-column">
														<div className="font-weight-bold">{item.userName}</div>
														<Typography variant="caption" color="secondary">
															<strong>Reason:</strong> {category[item.key-1]}
															</Typography>
													</div>
												</div>
												<Typography variant="body1" className="py-2">
													Info: {item.report.substring(0,100)}
												</Typography>
												<div className="small">Created On: {LUX_ZONE(item.created_at)}</div>

												<div className="flex-fill d-flex align-items-center mt-4">
													<Avatar className="mr-2" 										
														src={item.adminPic? item.adminPic:'../../assets/avatar.png'} 
													/>
													<div className="d-flex ml-2 flex-column">
														<small><strong>
															Attending Admin:	{item.attendingAdminName? item.attendingAdminName: 'None yet'}
														</strong></small>
														
													</div>
												</div>
											</CardContent>
											<CardActions className="d-flex justify-content-between">
												<div className='mx-auto'>
													<Link to={`/reported-project-chat/${encode(item.allocation_id)}/${encode(item.userName)}`}>
														<Button variant="contained" size="small" 
															className="bg-custom-primary text-white" 
															disableElevation
														>
															View
														</Button>
													</Link>													
												</div>
												<IconButton
													onClick={()=>handleExpandClick(index)}
													aria-expanded={(indexToExpand === index) && expanded}
													aria-label="show more"
													className={clsx(classes.expand, {
										            [classes.expandOpen]: (indexToExpand === index) && expanded,
										          })}
												>
													<ExpandMoreIcon />
												</IconButton>
											</CardActions>
											<Collapse in={(indexToExpand === index) && expanded} timeout="auto" unmountOnExit>
									        <CardContent>
									          <Typography paragraph>
									            { item.report }
									          </Typography>
									        </CardContent>
									      </Collapse>
										</Card>
									</div>
								))}
								</div>
							</div>:
							null
						}
						<div className='d-flex w3-text-tiny justify-content-center'>
							{gettingReports && <CircularProgress style={{ fontSize: '5px' }} />}
						</div>
						</div>
					</div>
				</div>
			
	</div>);
}

export default ReportedProjects