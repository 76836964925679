import { ADMIN, INTENDED_PAGE, SUSPENDED_LOAD,
  PROFESSIONALS_LIST,
  LOAD_PRIVATE_CHATS,
  SOME_PAGE_NOT_FOUND,
  MARK_SELECTED_CHAT,
  LOAD_DESKTOP_PRIVATE_CHATS,
  LOAD_PC_DETAIL_CHANGES
} from "../constants"


export const adminInfo = (payload)=>{ return {type: ADMIN, payload}};
export const intendedPage = (payload)=>{ return {type: INTENDED_PAGE, payload}};
export const suspendedLoad = (payload)=>{ return {type: SUSPENDED_LOAD, payload}};
export const professionalsList = (payload)=>{return {type: PROFESSIONALS_LIST, payload}};
export const loadPrivateChats=(payload)=>{return{type:LOAD_PRIVATE_CHATS, payload}};
export const somePageNotFound = (payload)=>{return{type:SOME_PAGE_NOT_FOUND, payload}};
export const markSelectedChat = (payload)=>{return {type:MARK_SELECTED_CHAT, payload}};
export const loadDesktopPCs=(payload)=>{return{type:LOAD_DESKTOP_PRIVATE_CHATS, payload}};
export const loadPcDetailChanges=(payload)=>{return{type:LOAD_PC_DETAIL_CHANGES, payload}};