import React, { useEffect, useState, useRef} from 'react';
import {Button, CircularProgress, Paper } from '@material-ui/core';
import Axios from 'axios';
import { Table } from 'react-bootstrap';
import { PROXMIN } from '../../global/constants';
import { head, LUX_ZONE } from '../../global/gadget/gadget';
import { suspendedLoad } from '../../global/action';
import { connect } from 'react-redux';


const statuses = ['Unseen', 'Accepted', 'Completed', 'Rejected', 'Seen','Reassigned', 'Terminated']
const header = ["S/N",'Username', 'Description' , 'status',  "Rated", 'Rating', 'StatusTime', 'timeLimit', 'Created_at', 'Options'];
const SingleClientProjects=({id, user, modify, suspendedLoad, setSuspendedLoad})=>{

  const mounted = useRef(true);
  const scrollBox = useRef(document.getElementById('scrollBox'));
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [projectList, setPRojectList] = useState(null);
  const [fetchingMoreProjects, setFectingMoreProjects] = useState(false);
  const [selected, setSelected] = useState(false);



	useEffect(()=>{
		const fetchPRojects=()=>{			
			Axios.get(`${PROXMIN}/professional_participant_projects/${id}/${user}` , {headers: head()})
				.then(res=>{
          // if(!mounted.current) return;
          if(res.data.success){
            setPRojectList(res.data.data);
            setLoadingProjects(false);
          }
				})
				.catch(err=>{
          if(!mounted.current) return;
					setLoadingProjects(false);
				})
		}

		if(!projectList && user) fetchPRojects();
		return ()=>{mounted.current = false}

	},[projectList, id, user]);

	useEffect(()=>{
		if(!projectList || !projectList.next_page_url || fetchingMoreProjects) return;
		/** This  methods fetches more clients as virtual scrolling*/
		const fetchMoreClients=()=>{
			if(!projectList.next_page_url) return;
			setFectingMoreProjects(true);
			Axios.get(projectList.next_page_url, {headers: head()})
			.then(res=>{
				if(res.data.success){
					res.data.data.data = [...projectList.data, ...res.data.data.data];
					setPRojectList(res.data.data);
					setFectingMoreProjects(false);
				}
			})
			.catch(err=>{
        if(!mounted.current) return;
				setFectingMoreProjects(false);
			})
		}

		/** The placeholder for event listerner */
		const virtualScroll =()=>{
			if(scrollBox.current.scrollTop+scrollBox.current.clientHeight >= scrollBox.current.scrollHeight){
				fetchMoreClients();
			}
		}

		if(!scrollBox.current) scrollBox.current = document.getElementById('scrollBox');
		if(scrollBox.current) scrollBox.current.addEventListener('scroll', virtualScroll);
		return ()=>{if(scrollBox.current) scrollBox.current.removeEventListener('scroll',virtualScroll);}
  });
  
  const processSuspect=()=>{
    let cursor = document.getElementById('checker');
    if(cursor.checked){
      if(!suspendedLoad){
        setSuspendedLoad({pro: id, associates:[user]});
      }else{
        if(suspendedLoad.associates.indexOf(user) > -1) return;
        setSuspendedLoad({pro:id, associates: [...suspendedLoad.associates, user]})
      }
    }else{
      if(!suspendedLoad) return;
      let index = suspendedLoad.associates.indexOf(user)
      if( index < 0 ) return;
      let demo = {...suspendedLoad};
      demo.associates.splice(index, 1);
      setSuspendedLoad(demo);
    }
  }


  useEffect(()=>{
    if(suspendedLoad && suspendedLoad.associates.indexOf(user) > -1){
      setSelected(true);
    }else{
      setSelected(false);
    }
  },[suspendedLoad, user])



  return(<div className='w3-animate-zoom'>
    {!user? 
    <div>
      <Paper variant='outlined'  square
            className='d-flex justify-content-center py-1 mb-1 w3-light-grey'
            style={{marginLeft:'3px'}}		
          >	
          <strong>
            Please Select Client
          </strong>
      </Paper>
    </div>:
    <div>
      <div className='row'>
        <div className='col-12 mb-1'>
          <Paper variant='outlined'  square
            className=' py-1 mb-1 w3-light-grey'
            style={{marginLeft:'3px'}}		
          >	
            {loadingProjects?
              <div className='d-flex justify-content-center'>
                <CircularProgress  />
              </div>:
              <div className='d-flex justify-content-around'>
                {projectList && projectList.data &&  projectList.data.length > 0 &&
                  <div >
                    <strong>Suspected?: </strong>
                    <input type='checkbox' name='checker' id='checker' 
                      onChange={processSuspect} checked={selected}
                    />
                  </div>
                }
                <div >
                  <strong> 
                    {projectList && typeof projectList.total === 'number' && projectList.total?
                      `Total: ${projectList.total}`:
                      "No Project fetched"
                    }
                  </strong>
                </div>

              </div>
            }	
          </Paper>
        </div>
      </div>
      <div id="scrollBox" style={{ height:'74vh', overflowY:'auto', overflowX:'hidden'}}>
        {projectList && projectList.data ?
          <Table responsive striped hover size="sm">
            <thead>
              <tr>
                {header.map((data, index)=>
                  <td key={index} >
                    <strong>{data}</strong>
                  </td>
                )}
              </tr>
            </thead>
            <tbody>
              {projectList.data.map((pro,index)=>
                <tr key={`${pro.userName}-${index}`}
                  className='w3-hover-light-gray'
                >
                  <td>{index+1}</td>
                  <td>{pro.userName}</td>
                  <td>{pro.description}</td>
                  <td>{statuses[pro.status]}</td>
                  <td>{pro.rated?'Yes':'No'}</td>
                  <td>{pro.rate}</td>
                  <td>{LUX_ZONE(pro.statusTime)}</td>
                  <td>{pro.timeLimit}</td>
                  <td>{LUX_ZONE(pro.created_at)}</td>
                  <td>
                    <Button style={{ background:'#6EE5B2' }} disableElevation
                      onClick={()=>{
                        modify('projectChat', pro.id);
                      }}
                    >
                      Chats
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>:
          null
        }
        <div className='d-flex justify-content-center'>
          {fetchingMoreProjects && <CircularProgress style={{ fontSize: '5px' }} />}
        </div>
      </div>
    </div>}
  </div>)
}

const mapStateToProps = (state) => {
  return {
    suspendedLoad: state.suspendedLoad,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSuspendedLoad: (payload)=>dispatch(suspendedLoad(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleClientProjects);