import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Axios from 'axios';
import { CHAT_LIKE_TIME, head } from '../../global/gadget/gadget';
import { PROXMIN } from '../../global/constants';
import { CircularProgress } from '@material-ui/core';
import { Star, SwapHoriz } from '@material-ui/icons';

const classes = {
    saveBtn: {
      color: '#F9FBFF',
      borderColor: '#ff7420',
      borderRadius: '18.5px',
      background: 'linear-gradient(180deg, #FA9347 0%, #F07672 99.99%, #F07672 100%)',
    },
    topBg: {
        background: 'linear-gradient(to right, #4285F4, #4FCDFF)',
        height: '200px',
    },
    roundedClass: {
      borderRadius: '18.5px',
    },
    // const imageCard = {
    //     height: '150px',
    //     width: '100%',
    //     overflow: 'hidden',
    //     position: 'relative',
    //     background: 'rgba(0, 0, 0, 0.5)',
    // }
    avatarStyle: {
        height: 40,
        width: 40
    },
    userChatStyle: {
        float:'right',
        maxWidth: '85%',
        backgroundColor: '#F9FBFF',
        borderRadius: '15px 0 15px 15px',
    },
    otherChatStyle: {
        float:'left',
        maxWidth:'85%',
        borderRadius: '0 15px 15px 15px',
    },
    textBox: {
        maxWidth: '100%',
    },
    chatInput: {
        height: '50px',
    },
    chatInputDiv: {
        position: 'absolute',
        zIndex: '1',
        backgroundColor: '#FFFFFF',
        width: '96%',
        bottom: '0',
        padding: '7px 12px',
    },
    closeBtn: {
        position: 'absolute',
        right: 0,
        marginRight: '7px',
        zIndex: 50,
      }
}


const ProjectChat=({id, modify})=> {
	 
	
	/** This is used to prevent data leakage */
  const mounted = React.useRef(true);
  
	const [project, setProject] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

	React.useEffect(()=>{
		const fetchMessage=()=>{			
			Axios.get(`${PROXMIN}/project_chats/${id}`, {headers: head()})
				.then(res=>{
          setProject(res.data.data[0]);
          setLoading(false);
				})
				.catch(err=>{
          if(!mounted.current) return;
          setLoading(false);
				})
		}

    if(!project && id){
      fetchMessage();
    }else{
      setLoading(false);
    }

		return ()=>{mounted.current = false}

  },[id, project]);
  
  const backToUnique=()=>{
    if(project.userName){
      modify('singleClientProjects', project.userName);
    }
  }


	return (
		<div className="container-fluid">
      {loading?
        <div>
          <CircularProgress fontSize='small' />
        </div>:
        <div>
          <div>
            <Paper variant='outlined'  square
              className='d-flex justify-content-center py-1 mb-1 w3-light-grey'
              style={{marginLeft:'3px'}}		
            >	
              <SwapHoriz onClick={backToUnique}  color='primary'/>
            </Paper>
          </div>
          <div id='scrollBox' style={{height:'70vh', overflowY: 'auto'}}>
            <div className='card-body'>
              {project && project.messages.length < 1?
              <div className=' d-flex justify-content-center mt-5'>
                <strong>NO MESSAGE</strong>
              </div>:
              <div>
                {project && project.messages && project.messages.reverse().map((message, index) =>
                  <div key={index} >
                    { (message.type === 2 || message.type === 1) &&
                      <Paper elevation={0}  className="p-2 py-1 mb-2 border"
                        style={message.userName === project.userName ? classes.userChatStyle : classes.otherChatStyle}
                      >
                        <div className='d-flex justify-content-between'>
                          <div className='mr-2'><small><strong>	{message.userName}</strong></small></div>
                          <div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
                        </div>
                        <Typography style={ {fontSize: '0.9rem'} }>
                            {message.message}
                        </Typography>
                      </Paper>
                    }
                    { (message.type === 22 || message.type === 12 || message.type === 21 )&&
                      <Paper elevation={0} className="p-2 py-1 mb-2 border" 
                        style={message.userName === project.userName ? classes.userChatStyle : classes.otherChatStyle}
                      >
                        <div className='d-flex justify-content-between'>
                          <div className='mr-2'>
                            <small><strong>	
                              {message.userName} {message.type === 21 && <Star />}
                            </strong></small>
                          </div>
                          <div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
                        </div>
                        <Typography style={ {fontSize: '0.9rem'} }>
                          <img src={`${message.message}`} alt="img" width='150' height='150' />
                        </Typography>
                      </Paper>
                    }
                    <div className="clearfix"></div>
                  </div>
                ) }
              </div>
              }
                        
            </div>
          </div>

        </div>
      }
		</div>
	);
	
}
export default ProjectChat;