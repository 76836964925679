import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { suspendedLoad } from '../../global/action';
import { PROXMIN } from '../../global/constants';
import { head } from '../../global/gadget/gadget';



const messages = "message";

const SuspendBox=({id, suspendedLoad, status})=>{

  const [match, setMatch] = useState(true);
  const [penalty, setPenalty] = useState(0);
  const [message, setMessage] = useState(messages);
  const [sending, setSending] = useState(false);
  const [errMes, setErrMes] = useState(null);
  const [successMes, setSuccessMes] = useState(false);
  
  useEffect(()=>{
    if(suspendedLoad && id){
      if(suspendedLoad.pro && suspendedLoad.pro !== id){
        setMatch(false);
      }
    }

  },[id,suspendedLoad])

  const processPenalty=(e)=>{
    setPenalty(e.target.value);
  }

  const processMessage=(e)=>{
    setMessage(e.target.value);
  }

  const suspend=(e)=>{
    e.preventDefault();
    if(sending) return;
    const form = new FormData();
    form.append('penalty', penalty)
    if(suspendedLoad && suspendedLoad.associates.length > 0){
      for (let i = 0; i < suspendedLoad.associates.length; i++) {
        form.append(`associates[${i}]`, suspendedLoad.associates[i]);
        
      }
    }else{
      form.append('associates', [])
    }
    form.append('message', message);
    setSending(true);
    setSuccessMes(false);
    setErrMes(null);
    Axios.post(`${PROXMIN}/suspend_professional/${id}`, form , {headers: head()})
    .then(res=>{
      if(res.data.success){
        console.log(res.data.data);
        setSuccessMes(true);
        setSending(false);
      }else{
        if(res.data.Error === 'Already Suspended'){
          setErrMes('User Already Suspended');
        }
      }
      setSending(false);
    })
    .catch(err=>{
      setErrMes('Something went wrong')
      setSending(false);
    })
  }

  return(<div>
    <div className='row'>
      <div className='col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4'>
        {!match?
          <div className='d-flex justify-content-center'>
            Error, please ensure suspected professional matches current professional
          </div>:          
          <div className=' pl-3'>
          <Form>
            <Form.Group controlId="formBasicList">
              <Form.Text className="text-muted pt-3">
                <h6>Account </h6>
                <strong>  
                  {suspendedLoad && suspendedLoad.pro?
                    suspendedLoad.pro: id
                  }
                </strong>
              </Form.Text>
              <Form.Text className="text-muted">
                <h6>Associates</h6>
                {suspendedLoad && suspendedLoad.associates.length > 0?
                <div>
                  {suspendedLoad.associates.map((asso,index)=>
                    <div key={index}>{asso}</div>
                  )}
                </div>:
                <div>No associate selected</div>
                }
              </Form.Text>
              
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea"  defaultValue={message}
                rows={5} onChange={processMessage}  
              />
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Label>Penalty</Form.Label>
              <Form.Check name='penalty' value={0} type="radio"
               label="None" id='p1' onChange={processPenalty}
              />
              <Form.Check name='penalty' value={1} type="radio"
               label="Remove all points" id='p2' onChange={processPenalty}
              />
              <Form.Check name='penalty' id='p3' value={2} type="radio"
               label="Remove associated points" onChange={processPenalty}
              />
            </Form.Group>
            <Form.Group className='my-2 d-flex justify-content-center' controlId="errMessage">
              <div><small className='text-danger'>{errMes && errMes}</small></div>
              <div><small>{successMes && "Operation was successful"}</small></div>
            </Form.Group>
            <div className='d-flex justify-content-center'>
              <Button variant="primary" disabled={sending || status === 2} onClick={suspend}>
                Sanction
              </Button>
            </div>
          </Form>
        </div>}

      </div>
    </div>
  </div>)
}

const mapStateToProps = (state) => {
  return {
    suspendedLoad: state.suspendedLoad,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSuspendedLoad: (payload)=>dispatch(suspendedLoad(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuspendBox);