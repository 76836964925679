import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PROXMIN } from '../../global/constants';
import { head } from '../../global/gadget/gadget';
import Operations from './Operations';


const OperationsCover=({match})=>{

  const mounted = useRef(true);
  const {params} = match;

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [statusConfirm, setStatusConfirm] = useState(false);
  const [status, setStatus] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [wentWrong, setWentWrong] = useState(false);

   useEffect(()=>{
    const confirmUser=()=>{
      Axios.get(`${PROXMIN}/confirm_participant/${params.id}`, {headers: head()})
      .then(res=>{
        if(res.data.success){
          if(!mounted.current) return;
          if(res.data.data){
            setConfirm(true);
            setLoading(false);
          }
        }

      })
      .catch(err=>{
        if(!mounted.current) return;
        if(err.response && err.response.status === 404){
          setNotFound(true);
        }else if(err.response && err.response.status === 401){
          localStorage.clear();
          history.push('/')
        }else{
          setWentWrong(true);
        }
        mounted.current && setLoading(false);
      })
    }

    confirmUser();

    return ()=>{
      mounted.current = false;
    }

  }, [params.id, history])


  useEffect(()=>{
    if(params.status && !status){
      try {
        let result = window.atob(window.atob(params.status));
        if(typeof Number(result) === 'number'){
          setStatusConfirm(true)
          setStatus(result);
          console.log(result)
        }else{
          setStatusConfirm(false)
          setNotFound(true);
        }
      } catch (e) {
        setStatusConfirm(false)
        setNotFound(true);
      }
    }
  },[params.status, status])

  return(<div>
    {loading?
      <div className='d-flex justify-content-center'>
        <div className='w3-spin spinner w3-circle mt-2'></div>
      </div>:
      <div>
        {notFound?
          <div>
            Not found
          </div>:
          <div>
            {wentWrong?
              <div> 
                Something went wrong
              </div>:
              <div>
                {confirm && status &&  statusConfirm &&
                  <Operations id={params.id} status={status} />
                }

              </div>
            }
          </div>
        }
      </div>
    }
  </div>)
}

export default OperationsCover;