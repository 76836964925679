import React from 'react';
import { Table } from 'react-bootstrap';



const TableTest=()=>{

  return(<div>
    <Table responsive >
      <thead>
        <tr>
          {[...new Array(25)].map(data=>
              <td>AAAA</td>
          )}
        </tr>
      </thead>
      <tbody>
        {[...new Array(10)].map(data=>
          <tr>
            {[...new Array(25)].map(data=>
              <td>jhjhjjjjjj</td>
            )}
          </tr>
        )}

      </tbody>
    </Table>
  </div>)
}
export default TableTest;