import React from 'react'
import Avatar from '@material-ui/core/Avatar';
// import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Axios from 'axios';
import { PROXMIN} from '../../global/constants';
import { head } from '../../global/gadget/gadget';
import { Button, CircularProgress, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Cancel, Delete, Sync } from '@material-ui/icons';
import { Table } from 'react-bootstrap';
import MessageUser from '../ChatUser/MessageUser';


const header = ["S/N", 'Picture', 'UserName', 'FirstName', 'LastName', "Status", 'Role',  'Message', 'Suspend', 'Delete', 'Explore'];
const Users =()=>{
/** This is used to prevent data leakage */
	const mounted = React.useRef(true);
	const scrollBox = React.useRef(document.getElementById('scrollBox'));

	const [users, setUsers] = React.useState(null);
	const [loadingUsers, setLoadingUsers] = React.useState(true);
	const [fetchingMoreUsers, setFetchingMoreUsers] = React.useState(false);
	const [showChatBox, setShowChatBox] = React.useState(false);
	const [selectedUser, setSelectedUser] = React.useState(null);


	React.useEffect(()=>{
		const fetchUsers=()=>{			
			Axios.get(`${PROXMIN}/get_all_users` , {headers: head()})
				.then(res=>{
					setUsers(res.data.data);
					setLoadingUsers(false);
				})
				.catch(err=>{
					console.log(err);
					setLoadingUsers(false);
				})
		}

		if(!users) fetchUsers();
		return ()=>{mounted.current = false}

	},[users]);

	React.useEffect(()=>{
		if(!users || !users.next_page_url) return;
		/** This  methods fetches more professionals as virtual scrolling*/
		const fetchMoreUsers=()=>{
			if(!users.next_page_url) return;
			setFetchingMoreUsers(true);
			Axios.get(users.next_page_url, {headers: head()})
			.then(res=>{
				if(res.data.success){
					res.data.data.data = [...users.data, ...res.data.data.data];
					setUsers(res.data.data);
					setFetchingMoreUsers(false);
				}
			})
			.catch(err=>{
				setFetchingMoreUsers(false);
			})
		}

		/** The placeholder for event listerner */
		// const virtualScroll =()=>{
		// 	if(window.pageYOffset+window.innerHeight >= document.body.offsetHeight){
		// 		fetchMoreUsers();
		// 	}
		// };

		// window.addEventListener('scroll', virtualScroll);
		// return ()=>{ window.removeEventListener('scroll',virtualScroll);}
		const virtualScroll =()=>{
			// console.log(scrollBox.current.scrollTop, scrollBox.current.clientHeight, scrollBox.current.scrollHeight);
			if(scrollBox.current.scrollTop+scrollBox.current.clientHeight >= scrollBox.current.scrollHeight){
				fetchMoreUsers();
			}
		}

		if(!scrollBox.current) scrollBox.current = document.getElementById('scrollBox');
		if(scrollBox.current) scrollBox.current.addEventListener('scroll', virtualScroll);
		return ()=>{if(scrollBox.current) scrollBox.current.removeEventListener('scroll',virtualScroll);}
	},[users]);

	
	const processChat=(user)=>{
		setSelectedUser(user);
		setShowChatBox(true);
	}

	return (<div className="container-fluid">			
		<div className="row">
			<div className="col-12">
				{/* <div className="d-flex justify-content-between mb-3">
					<h4>Users</h4>
					<div className="d-flex align-content-center">
						<div className="mr-2">
							<input type="search" className="form-control" placeholder="search" />
						</div>
						<div>
							<select className="custom-select">
								<option>Filter</option>
								<option>Name</option>
								<option>Date</option>
							</select>
						</div>
					</div>
				</div> */}
				{/* Filter here later */}
			</div>
		</div>

		<div className='row'>
			<div className='col-12 mb-1'>
				<Paper variant='outlined'  square
					className='d-flex justify-content-center py-1 mb-1 w3-light-grey'
					style={{marginLeft:'3px'}}		
				>
					{loadingUsers?
						<div>
							<CircularProgress fontSize='small' />
						</div>:
						<b>
							 
							{users && typeof users.total === 'number' && users.total?
								`Total: ${users.total}`:
								"No User fetched"
							}
						</b>
					}
				</Paper>
			</div>
		</div>

		<div id="scrollBox" style={{ height:'70vh', overflowY:'auto', overflowX:'hidden'}}>
			{users && users.data ?
				<Table responsive striped hover size="sm">
					<thead>
						<tr>
							{header.map((data, index)=>
								<td key={index} >
									<strong>{data}</strong>
								</td>
							)}
						</tr>
					</thead>
					<tbody>
						{users.data.map((user,index)=>
							<tr key={`${user.userName}-${index}`}
								className='w3-hover-light-gray'
							>
								<td>{index+1}</td>
								<td>
								<Avatar 									
									src={user.profilePic? user.profilePic:'../../assets/avatar.png'} 
								/>
								</td>
								<td>{user.userName}</td>
								<td>{user.firstName}</td>
								<td>{user.lastName}</td>								
								<td>
									{!user.active?
									 	<Cancel />:
										user.active ===1?
										<Sync color='primary' />:
										<Delete color='secondary' />  
									}
								</td>
								<td>
									{!user.role?"Norm":"Pro"	}
								</td>
								<td>
									<Button className='customPrimaryBgColor text-white' disableElevation
										onClick={()=>{processChat(user.userName)}}
									>
										Message
									</Button>
								</td>
								<td><Button style={{ background:'#0063ab', color:'white' }} disableElevation>Suspend</Button></td>
								<td><Button style={{ background:'#acddff' }} disableElevation>Delete</Button></td>
								
								<td>
									<Link style={{ textDecoration:'none' }}
									to={`/user-operations/${user.userName}/${window.btoa(window.btoa(user.active))}`} 
										>
										<Button style={{ background:'#6EE5B2' }} disableElevation>View</Button>
									</Link>
								</td>
							</tr>
						)}
					</tbody>
				</Table>:
				null
			}
			<div className='d-flex justify-content-center'>
				{fetchingMoreUsers && <CircularProgress style={{ fontSize: '5px' }} />}
			</div>
		</div>

		<div className='placing'>
			{(showChatBox && selectedUser) &&
					<MessageUser user={selectedUser} condition={setShowChatBox} />
			}
		</div>
	</div>);
	
}

export default Users





/* {users.data.map((user, index) => (
						<div className="row" key={`${user.userName}-${index}`}>
							<div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 mb-1">
								<div className="d-flex align-items-center justify-content-between">
									<div className="d-flex align-items-center">
										<Avatar className="mr-2" 
											src={user.profilePic? user.profilePic: '../../assets/avatar.png'}											
										/>
										<div className="d-flex flex-column">
											<div>{user.userName}</div>
											<div className="small">{`${user.firstName} ${user.lastName}`}</div>
										</div>
									</div>
									<div>{user.active? `${user.active === 1? 'Active':'Suspended'}`:'Busy'}</div>
									<div>{user.role?'Professional':'Ordinary'}</div>
									<div>{user.email_verified_at?'Verified':'Unverified'}</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
										<button className="btn btn-primary rounded-pill btn-sm">View Profile</button>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<select className="custom-select">
											<option></option>
											<option>Send message</option>
											<option>Suspend account</option>
											<option>Delete account</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					)) } */