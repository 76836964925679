import { Avatar, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { PROXMIN, PROXY } from '../../global/constants';
import { head } from '../../global/gadget/gadget';


const holders={

    verified: "Important notice",

    inactive_users: `We notice your inactiveness on our platform. Please login into your account
    now to enjoy our free services. You can assign a project to our professionals or make use
    of templates, all for free.`,

    inactive_pros: `We notice that you have many projects undone on your accounts with us.
    Your clients would like the projects to done as soon as possible. You can as well reject a project
    if you are busy which leads to point deduction with 1 point. Please be reminded that your point grows
    with the ratings on the completed projects from your clients.`,

    professionals: ` Mail professionals`,

    unverified: `We notice that your account with us has not been
    confirmed in the email provided. Please login into your account now
    and click on resend confirmation email or change the email to receive the new
    confirmation lin`,

    private:"Private message to some users",
    
    no_rater: `We notice that you have not rated your compeleted project(s) on your account with us.
    Please note that our professionals are compensated according to the rating you
    give to the project when it is completed.`,
}

 const links={
  verified: 'mail_all_users',
  professionals: 'mail_professionals',
  no_rater: 'mail_no_raters',
  unverified: 'mail_unverified_users',
  inactive_users: 'mail_inactive_users',
  inactive_pros: 'mail_inactive_professionals',
  private: 'mail_some_users',
}


const CentralMail=()=>{

  const mounted = useRef(true);
  const [tabKey, setTabKey] = useState('verified');
  const [showCheck, setShowCheck] = useState(true);
  const [sending, setSending] = useState(false);
  const [users, setUsers] = useState([]);
  const [done, setDone] = useState(false);
  const [errs, setErrs] = useState(null);
  const [modal, setModal] = useState(false);

  const [searching, setSearching] = useState(false)
  const [lastSearch, setLastSearch] = useState('');
  const [somethingWentWrong, setSomethingWentWrong]= useState(false);
  const [val, setVal] = useState(null);
  const [result, setResult] = useState(null);

  const setActiveKey=(key)=>{
    setTabKey(key)
    const BOX = document.getElementById('box');
    if(BOX){
      BOX.value = holders[key];
    }
    if(key === 'private') setModal(true);
  }

  const storeUsers=(e)=>{
      const E = e;
      if(E.target.checked){
        if( users.indexOf(E.target.value) === -1){
          setUsers([...users, E.target.value]);
        }
      }else{
        if( users.indexOf(E.target.value) > -1){
          const index = users.indexOf(E.target.value);
          let temp = [...users];
          temp.splice(index,1)
          setUsers(temp);
        }
      }
  }

  const sendMail=()=>{
    if(sending) return; 
    const box = document.getElementById('box').value.trim();
    if(!box) return setErrs('Message required');
    const form = new FormData();


    form.append('message', box);
    if(tabKey === 'private'){
      if(users.length < 1) return setErrs('Select users');;
      for(let i = 0; i < users.length; i++){
        form.append(`users[${i}]`, users[i])
      }
    }
    setSending(true);
    Axios.post(`${PROXMIN}/${links[tabKey]}`, form, {headers: head()})
    .then(res=>{
      if(!mounted.current) return;
      if(res.data.success){
          setDone(true);
          setSending(false);
      }else{
        if(res.Error === 'Intruder'){
          setErrs('Something went wrong');
          setSending(false);
        }
      }
    })
    .catch(err=>{
      mounted.current && setErrs('Something went wrong');
      mounted.current && setSending(false);
    })
  }

  // const modify=async(key, user)=>{
  //   await setSelectedUser(user);
  //   await setTabKey(key);
  // }


  const searchNow=(e)=>{
    e.preventDefault();
    if(searching) return;
    const search = val.trim();
    if(!search) return;
    if(lastSearch === search) return;
    setSearching(true);
    setLastSearch(search);
    
    if(somethingWentWrong) setSomethingWentWrong(false)
    Axios.get(`${PROXY}/search_participants/${search}`)
    .then(async(res)=>{
        // alert('arrived');
      if(!mounted.current) return;
      if(res.data.success){
        await setResult(res.data.data);
        // console.log(res.data.data);
        await setSearching(false);
      }else{
        if(res.data.Error === 'Intruder'){
          setSomethingWentWrong(true);
          await setSearching(false);
        }
      }
    })
    .catch(async(err)=>{
      if(!mounted.current) return;
    //   if(err)console.log(err)
      await setSearching(false);
      await setSomethingWentWrong(true);
    })
}





  useEffect(()=>{
      const lister = document.getElementById('lister');
      if(!lister) return;
      const loadMore=()=>{
          if(lister.clientHeight +lister.scrollTop >= lister.scrollHeight ){
              if(searching) return;
              if(!result.next_page_url) return;
              mounted.current && setSearching(true);
              Axios.get(result.next_page_url)
              .then(async(res)=>{
                  if(res.data.success){
                      const tempData = [...result.data, ...res.data.data.data];
                      res.data.data.data = tempData;
                      await setResult(res.data.data);
                      mounted.current && setSearching(false);
                  }
              }).catch(err=>{
                  // if(err)console.log(err);
                  mounted.current && setSearching(false);
              });
          }
      }
      lister.addEventListener('scroll', loadMore);

      return()=>{
          if(lister)lister.removeEventListener('scroll', loadMore);
      }
      
  })

  return(<div>
    <Tabs  
      transition={false} id="noanim-tab-example"
      activeKey={tabKey}
      onSelect={setActiveKey}

    >
      <Tab eventKey="verified" title="All">
      {(tabKey === 'verified') &&
        "Verified"
      }
      </Tab>
      <Tab eventKey="professionals" title="Professionals">
        Professsionals
      </Tab>
      <Tab eventKey='inactive_users' title='Inactive Users'>
        {(tabKey === 'inactive_users') &&
          "Inactve"
        }
      </Tab>
      <Tab eventKey='inactive_pros' title='Inactive Professionals'>
        {(tabKey === 'inactive_pros') &&
          "Inactve"
        }
      </Tab>
      <Tab eventKey='unverified'  title='Unverified'>
        {(tabKey === 'unverified') &&
          " Unverified"
        }
      </Tab>
      <Tab eventKey='no_rater' title='No Raters'>
        {(tabKey === 'no_rater') && 
          "No rater"
        }
      </Tab>
      <Tab eventKey='private' title='Private'>
        {(tabKey === 'private') && 
          <div className='d-flex justify-content-center mt-2'>
            {!modal &&
              <div>
                {users && users.length > 0 && users.map((user, index)=>
                  <div key={index}>
                    {index+1} {user}
                  </div>
                )}
              </div>
            }
            <Modal 
              show={modal}
              onHide={()=>setModal(false)}
              animation={false}
            > 
              <Modal.Body>
                <div style={{ marginTop:'60px' }}>
                  <div>
                    <div>
                      <div className='mb-1' style={{position:'relative'}}>
                        {result && result.data &&
                          <div className='pl-3'>
                            <strong>
                              {Number(result.total) > 1?
                                `${result.total} results`:
                                `${result.total} result`
                              } found
                            </strong>
                          </div>
                        }
                        <div className='px-2 py-1 w3-circle w3-light-grey'
                          onClick={()=>{setModal(false)}} 
                          style={{position:'absolute', top:0, right:0, cursor:'pointer'}}>
                          X
                        </div>
                      </div>
                      <div className="input-group pb-2 mt-3 pt-1 px-3">
                        <input id='searchBox'  type="search"
                          className="form-control rounded-pill" placeholder="Search" aria-label="Search Box"
                          onChange={(e)=>{setVal(e.target.value)}}
                        />
                        <div className="input-group-append">
                          <button onClick={searchNow}
                            className="btn btn-sm rounded-pill ml-2 px-2 customPrimaryBgColor" 
                            style={{height: '35px', color: '#F9FBFF'}}
                          >
                            <Search fontSize="small" />
                          </button>
                        </div>
                      </div>
                      {searching &&
                        <div className='mb-1 d-flex justify-content-center'>
                          <div className="spinner-grow text-muted"></div>
                          <div className="spinner-grow text-info"></div>                                    
                        </div>
                      }
                    </div>
                    <div id='lister' className='w3-card px-5' style={{maxHeight: '78vh', overflowY:'auto'}}>
                      {( result && result.data && result.data.length > 0)?
                        <div className='mt-1'>
                          { result.data.map((data, index)=>
                            <div key={index} 
                                className="w3-round align-items-center mb-2 smallerText w3-hover-pale-blue"
                            >
                              <div className='d-flex justify-content-between'>
                                <div className="d-flex">
                                  <Avatar 
                                    style={{width:'50px', height:'50px'}}
                                    alt="avatar1" 
                                    className="img-fluid mr-2" 
                                    src={data.profilePic? data.profilePic : 'noImage' } 
                                  />
                                  <div className="d-flex flex-column">
                                    <Typography variant="subtitle1">
                                      {data.userName}
                                    </Typography>
                                    <Typography variant="subtitle2" className="font-weight-light">
                                      {`${ data.firstName} ${data.lastName}`}
                                    </Typography>
                                  </div>
                                </div>
                                <div>
                                  <input onChange={storeUsers} value={data.userName} 
                                    type='checkbox' checked={users.indexOf(data.userName) > -1}
                                  />
                                </div>                                      

                              </div>
                            </div>
                            )}
                              
                          </div>:
                          <div>
                            No match found 
                          </div>
                          }
                      </div>
                      <div>{somethingWentWrong && 
                          <small className='text-danger'>Oops! Something went wrong</small>
                        }
                      </div>
                  </div>             
                </div>
              </Modal.Body>
            </Modal>

          </div>
        }
      </Tab>
    </Tabs>
    <div className='my-5 px-2'>
      <div className='d-flex justify-content-center'>
        <div>
            <div>
              <h6>Message</h6>
              <textarea id='box' cols='50' rows='8' defaultValue={holders[tabKey]} />
            </div>
            <div>
              {showCheck?
                <div className='d-flex justify-content-center'>
                  <button className=' btn customPrimaryBgColor text-white rounded-pill'
                    onClick={()=>setShowCheck(false)}
                  >
                    Continue
                  </button>
                </div>:
                <div>
                  <h6 className='d-flex justify-content-center'> 
                    Please check the message well before sending
                  </h6>
                  <div className='d-flex justify-content-center'>
                    <button className=' btn customPrimaryBgColor text-white rounded-pill'
                      onClick={sendMail}
                    >
                      Send Email
                    </button>
                  </div>
                  {sending &&
                    <div className='d-flex justify-content-center'>
                      <div className='w3-spin spinner w3-circle mt-2'></div>
                    </div>
                  }
                  {done &&
                    <div className='d-flex justify-content-center'>
                      <strong><small>Operation was successful</small></strong>
                    </div>
                  }
                  {errs &&
                    <div className='d-flex justify-content-center'>
                      <strong><small className='text-danger'>
                        {errs}
                      </small></strong>
                    </div>
                  }
                </div>
              }
            </div>
          
        </div>
      </div>
    </div>
  </div>)
}

export default CentralMail;


// mail_all_users
// mail_professionals
// mail_no_raters
// mail_unverified_users
// mail_inactive_users
// mail_inactive_professionals
// mail_some_users  
      
