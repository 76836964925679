import React from 'react';
import Avatar from '@material-ui/core/Avatar';
// import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Axios from 'axios';
import { CHAT_LIKE_TIME, head, LUX_ZONE } from '../../../global/gadget/gadget';
import { PROXMIN } from '../../../global/constants';
import { Collapse, Modal } from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

const classes = {
    saveBtn: {
      color: '#F9FBFF',
      borderColor: '#ff7420',
      borderRadius: '18.5px',
      background: 'linear-gradient(180deg, #FA9347 0%, #F07672 99.99%, #F07672 100%)',
    },
    topBg: {
        background: 'linear-gradient(to right, #4285F4, #4FCDFF)',
        height: '200px',
    },
    roundedClass: {
      borderRadius: '18.5px',
    },
    // const imageCard = {
    //     height: '150px',
    //     width: '100%',
    //     overflow: 'hidden',
    //     position: 'relative',
    //     background: 'rgba(0, 0, 0, 0.5)',
    // }
    avatarStyle: {
        height: 40,
        width: 40
    },
    userChatStyle: {
        float:'right',
        maxWidth: '85%',
        backgroundColor: '#F9FBFF',
        borderRadius: '15px 0 15px 15px',
    },
    otherChatStyle: {
        float:'left',
        maxWidth:'85%',
        borderRadius: '0 15px 15px 15px',
    },
    textBox: {
        maxWidth: '100%',
    },
    chatInput: {
        height: '50px',
    },
    chatInputDiv: {
        position: 'absolute',
        zIndex: '1',
        backgroundColor: '#FFFFFF',
        width: '96%',
        bottom: '0',
        padding: '7px 12px',
    },
    closeBtn: {
        position: 'absolute',
        right: 0,
        marginRight: '7px',
        zIndex: 50,
      }
}

const category = ['Not accepted', 'Not rated', 'No Response', 'Unclear'];
const statuses = ['Unseen', 'Accepted', 'Completed', 'Rejected', 'Seen','Reassigned', 'Terminated']


const ReportedProjectChat=({match})=> {
	 
	
	/** This is used to prevent data leakage */
	const mounted = React.useRef(true);

	const [allocationId, setAllocationID] = React.useState(window.atob(match.params.id));
	const [reporter, setReporter] = React.useState( window.atob(match.params.user) );
	const [project, setProject] = React.useState(null);
	const [showDetails, setShowDetails] = React.useState(false);
	const [terminateError, setTerminateError] = React.useState(false);
	const [collapse, setCollapse] = React.useState(false);
	// const [fetchinMorePros, setFetchingMorePros] = React.useState(false);

	React.useEffect(()=>{
		const fetchMessage=()=>{			
			Axios.get(`${PROXMIN}/reported_allocation/${allocationId}/${reporter}` , {headers: head()})
				.then(res=>{
					setProject(res.data.data[0]);
					setAllocationID(allocationId);
					setReporter(reporter);
				})
				.catch(err=>{
					// console.log(err)
				})
		}

		fetchMessage();

		return ()=>{mounted.current = false}

	},[reporter,  allocationId]);

	const detailsModalSet=async(condition)=>{
		await setShowDetails(condition);
		// console.log(project, 'is proj')
	}

	const conciseAllocation=(project)=>{
		const tempProject = {...project}
		delete tempProject.messages;
		delete tempProject.owner;
		delete tempProject.professional;
		return tempProject;
	}

	// React.useEffect(()=>{
	// 	const fetchProfessionals=()=>{			
	// 		Axios.get(`${PROXMIN}/get_all_professionals` , {headers: head()})
	// 			.then(res=>{
	// 				// console.log(res.data.data);
	// 				setProfessionals(res.data.data);
	// 				setLoadingPros(false);
	// 			})
	// 			.catch(err=>{
	// 				console.log(err);
	// 				setLoadingPros(false);
	// 			})
	// 	}

	// 	if(!professionals) fetchProfessionals();
	// 	return ()=>{mounted.current = false}

	// },[professionals]);

	const terminateProject=(e)=>{
		e.preventDefault();
		setCollapse(false);
		Axios.get(`${PROXMIN}/terminate_project/${allocationId}`, {headers: head()})
		.then(res=>{
			if(res.data.success){
				if(!mounted.current) return
				const tempData = {...project};
				tempData.status = 6;
				setProject(tempData);
				setCollapse(false);
			}
		})
		.catch(err=>{
			if(!mounted.current) return
			setTerminateError(true);
		})

	}

	const closeReport=(e)=>{
		e.preventDefault();
		setTerminateError(false);
		setCollapse(false);
		if(!project.report || !project.report.id) return
		Axios.get(`${PROXMIN}/terminate_project/${project.report.id}`, {headers: head()})
		.then(res=>{
			if(res.data.success){
				if(!mounted.current) return
				const tempProject = {...project};
				tempProject.report.id = 0;
				setProject(tempProject);
			}
		})
		.catch(err=>{
			if(!mounted.current) return
			setTerminateError(true);

		})
	}



	
	// console.log(match);
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-sm-12">
					<div>
						<div className="card-header align-items-center py-0">

							<div className="d-flex justify-content-between">
								<div>
									{ project && project.professional && 
										<Avatar alt='noImage' className="mr-2" 										
											src={  project.professional? project.professional.profilePic: ""} 
										/>
									}
									{ project && project.owner && 
										<Avatar alt='noImage' className="mr-2" 										
											src={  project.owner? project.owner.profilePic: ""	} 
										/>
									}
								</div>
								<div className="font-weight-bold mt-2">{reporter}</div>
								<div>
									<button 
										className={`w3-btn w3-light-gray 
												${project && project.report && !project.report.status? 'w3-text-red':'inherit'}
											`} 
										onClick={()=>{detailsModalSet(true)}}>
										Details
									</button>
								</div>
								{(project && project.report && Boolean(project.report.status) )&& 
									<div>
										<button className='w3-btn w3-light-gray' 
											onClick={() => setCollapse(!collapse)}
											aria-controls="example-collapse-text"
											aria-expanded={collapse}
										>
											ACTION
										</button>
										<Collapse in={collapse}>
										<div id="example-collapse-text" className='w3-indigo w3-round'>
											{(project.status !==5  && project.status !== 6 )&&
												<div >
													<button className='w3-btn ' 
														onClick={terminateProject}
													>
														Terminate Project
													</button>
												</div>
											}
											{ Boolean(project.report.status) &&
												<div>
													<button className='w3-btn ' 
															onClick={closeReport}
													>
														Close Report
													</button>
												</div>
											}
											<div>
												<Link to={`/reported-project-issue/${window.btoa(window.btoa(project.report.id))}`}>
												<button className='w3-btn w3-text-white ' 
													>
														View Ticket
													</button>
												</Link>
											</div>
										</div>
										</Collapse>
									</div>
								}
							</div>
							<div className='d-flex justify-content-center'>
								{terminateError && 
									<small className='text-danger'>
										Oops! Something went wrong
									</small>
								}
							</div>

						</div>
						<div className="card-body p-0">
							<div className="mb-4 position-relative p-2 pt-sm-0 pt-md-2" style={{height:'78.5vh'}}>
								<div id='scrollBox' style={{height:'68vh', overflowY: 'auto'}}>
									<div>
										{project && project.messages.length < 1?
										<div className=' d-flex justify-content-center mt-5'>
											<strong>NO MESSAGE</strong>
										</div>:
										<div>
											{project && project.messages && project.messages.map((message, index) =>
												<div key={index} >
													{ (message.type === 2 || message.type === 1) &&
														<Paper elevation={0}  className="p-2 py-1 mb-2 border"
															style={message.userName === reporter ? classes.userChatStyle : classes.otherChatStyle}
														>
															<div className='d-flex justify-content-between'>
																<div className='mr-2'><small><strong>	{message.userName}</strong></small></div>
																<div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
															</div>
															<Typography style={ {fontSize: '0.9rem'} }>
																	{message.message}
															</Typography>
														</Paper>
													}
													{ (message.type === 22 || message.type === 12 )&&
														<Paper elevation={0} className="p-2 py-1 mb-2 border" 
															style={message.userName === reporter ? classes.userChatStyle : classes.otherChatStyle}
														>
															<div className='d-flex justify-content-between'>
																<div className='mr-2'><small><strong>	{message.userName}</strong></small></div>
																<div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
															</div>
															<Typography style={ {fontSize: '0.9rem'} }>
																<img src={`${message.message}`} alt="img" width='150' height='150' />
															</Typography>
														</Paper>
													}
													<div className="clearfix"></div>
												</div>
											) }
										</div>
										}
															
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				{showDetails && 
					<ProjectDetails open={showDetails} opener={detailsModalSet} allocation={conciseAllocation(project)} />
				}
			</div>
		</div>
	);
	
}
export default ReportedProjectChat;

const ProjectDetails=({allocation, open, opener})=>{
	// console.log(allocation);
	
	return(<div>
		<Modal
    show={open}
    onHide={()=>{opener(false)}}
    >
      <Modal.Body className="py-4">
        <div>
          <IconButton 
            variant="secondary"  
            onClick={()=>{opener(false)}} 
            style={classes.closeBtn}
          >
            X
          </IconButton>
        </div> 
        {allocation &&
          <div className='d-block justify-content-center mt-3'>  
						<h3 className='d-flex justify-content-center'>Project Details</h3>
            <div className="d-flex  justify-content-center my-2">
							Status: {statuses[allocation.status]}
            </div>
						<div className="d-flex  justify-content-center my-2">
							Category: {allocation.catName? allocation.catName: 'Unknown'}
            </div>
						<div className="d-flex  justify-content-center my-2">
							Subcategory: {allocation.subName? allocation.subName: 'Unknown'}
            </div>
						<div className="small d-flex justify-content-center">
							Created on: {LUX_ZONE(allocation.created_at)}
						</div>
						{allocation.report && 
						<div>
							<h3 className='d-flex justify-content-center my-2'>Report Details</h3>
							<div className="d-flex justify-content-center">
						<strong>Atteding Admin: {allocation.report.adminName}</strong>
							</div>
							<div className="d-flex justify-content-center py-2">
								<Typography variant="caption" color="secondary">
									<strong>Reason:</strong> {category[allocation.report.key-1]}
								</Typography>
							</div>
							<div>
								<div className="d-flex justify-content-center">
									<small><strong>More Information</strong></small>
								</div>
								<div className=" d-flex justify-content-center py-2">
									{Boolean(allocation.report.report)? allocation.report.report: 'No additonal information added'}
								</div>
							</div>
							<div className="d-flex justify-content-center">
							<div className=" small d-flex justify-content-center pb-2">
								Created On:	{LUX_ZONE(allocation.report.created_at)}
							</div>
							</div>

						</div>
						}
            
          </div>
				}         
      </Modal.Body>
    </Modal>
	</div>)
}