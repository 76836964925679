export const PUXY = 'https://medilleryserver.medillery.com/public/';
export  const PROXY = 'https://medilleryserver.medillery.com/public/api';
export const PROXMIN = 'https://medilleryserver.medillery.com/public/api/admin';

// export const PUXY = 'http://127.0.0.1:8000/';
// export  const PROXY = 'http://127.0.0.1:8000/api';
// export const PROXMIN = 'http://127.0.0.1:8000/api/admin';

export const ADMIN = 'administrator';
export const INTENDED_PAGE = 'intended_page';
export const SUSPENDED_LOAD = 'suspended_load';
export const PROFESSIONALS_LIST = 'professionals_list';

export const SOME_PAGE_NOT_FOUND = 'some_page_not_found';
export const MARK_SELECTED_CHAT = 'mark_selected_chat';
export const LOAD_PRIVATE_CHATS = 'load_private_chats';
export const LOAD_DESKTOP_PRIVATE_CHATS = 'load_desktop_private_chats';
export const LOAD_PC_DETAIL_CHANGES = 'load_pc_detail_changes';