import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import SuspendBox from './SuspendBox';




const Actions=({id, status})=>{

  const [tabKey, setTabKey] = useState('suspend');
  // const [selectedUser, setSelectedUser] = useState(null);

  const setActiveKey=(key)=>{
    setTabKey(key)
  }

  const modifyer=async(key, user)=>{
    // await setSelectedUser(user);
    await setTabKey(key);
  }

  return(<div className='container-fluid we-animate-right mt-4'>
    <Tabs  
      transition={false} id="noanim-tab-example"
      activeKey={tabKey}
      onSelect={setActiveKey}

    >
      <Tab eventKey="suspend" title="Suspend">
        {(tabKey === 'suspend') && 
        <SuspendBox id={id} status={status} modify={modifyer} />}
      </Tab>
     
    </Tabs>

  </div>)
}

export default Actions;