import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { BrowserRouter as Router, Route/*, Redirect, Switch */} from 'react-router-dom';
import { Link /*, NavLink */} from 'react-router-dom';
import Home from '../home/Home.jsx';
import Users from '../users/Users.jsx';
import Professionals from '../professionals/Professionals.jsx';
import ReportedProjects from '../projects/ReportedProjects.jsx';
import ReportedProjectChat from '../projects/chat-section/ReportedProjectChat.jsx';
// import Login from '../auth/Login.jsx';
import ReportTicket from '../projects/chat-section/ReportTicket.js';
import TableTest from '../Table/TableTest.js';
import OperationsCover from '../professionals/OperationsCover.js';
import ListCover from '../UserMessage/ListCover.jsx';
import ChatCover from '../UserMessage/ChatCover.jsx';
import CentralMail from '../Mailer/CentralMail.js';
// import { adminInfo } from '../../global/action/index.js';
// import { connect } from 'react-redux';
// import Axios from 'axios';
// import { PROXY } from '../../global/constants/index.js';
// import { head } from '../../global/gadget/gadget.js';

const drawerWidth = 240;

const sidebarLinksOne = [
    { name: 'Dashboard', link: '/' },
    { name: 'Users', link: '/users' },
    { name: 'Professionals', link: '/professionals' },
    { name: 'Projects', link: '/projects' },
    { name: 'Reports', link: '/reported-projects' },
    { name: 'Templates', link: '/templates' },
    { name: 'Inbox', link: '/inboxs'},
    { name: 'Mails', link: '/mails'},

]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

function ResponsiveDrawer(props) {

  // const mounted = React.useRef(true);
  // const {setAdmin} = props; 
  // const [admin] = React.useState(props.admin);

  const { windower } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {sidebarLinksOne.map((text, index) => (
          <ListItem button key={index}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <Link to={text.link}><ListItemText primary={text.name} /></Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container = windower !== undefined ? () => windower().document.body : undefined;

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Medillery
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Route exact path="/" component={Home}></Route>
          <Route path="/users" component={Users}></Route>
          <Route path="/professionals" component={Professionals}></Route>
          <Route path="/reported-projects" component={ReportedProjects}></Route>
          <Route path="/reported-project-chat/:id/:user" component={ReportedProjectChat}></Route>
          <Route path="/reported-project-issue/:id" component={ReportTicket}></Route>
          <Route path='/test' component={TableTest} />
          <Route path="/professional-operations/:id/:status" component={OperationsCover} />
          <Route path="/inboxs" component={ListCover} />
          <Route path="/inbox/:id" component={ChatCover} />
          <Route path="/mails" component={CentralMail} />
          {/* <Route path="/login" component={Login}></Route> */}
        </main>
      </div>
    </Router>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windower: PropTypes.func,
};

// const mapStateToProps = (state) => {
//   return {
//     admin: state.admin
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setAdmin: (payload)=>dispatch(adminInfo(payload)),
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer);

export default ResponsiveDrawer;
