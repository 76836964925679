import { Avatar, Box, IconButton, Paper, Typography } from '@material-ui/core';
import { Attachment, ChevronRight } from '@material-ui/icons';
import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { PROXMIN, PROXY } from '../../../global/constants';
import { CHAT_LIKE_TIME, head, LUX_ZONE, staticPusherConfig } from '../../../global/gadget/gadget';

const classes = {
	userChatStyle: {
		float:'right',
		maxWidth: '85%',
		backgroundColor: '#F9FBFF',
		borderRadius: '15px 0 15px 15px',
	},
	otherChatStyle: {
		float:'left',
		maxWidth:'85%',
		borderRadius: '0 15px 15px 15px',
	},
	chatInput: {
		height: '50px',
	},
	chatInputDiv: {
		position: 'absolute',
		zIndex: '1',
		backgroundColor: '#F9FBFF',
		width: '100%',
		bottom: '0',
		padding: '7px 12px',
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    marginRight: '7px',
    zIndex: 50,
  } 
}

const category = ['Not accepted', 'Not rated', 'No Response', 'Unclear'];

const ReportTicket=({match, admin})=>{

  let mounted = useRef(true);
  const next_url = useRef(null);
  let scrollBox = useRef(window.document.getElementById('scrollBox'));
  let messageHolder = useRef(null);
  let reference = useRef(null);

  const [id] = useState(match.params.id);
  const [messageList, setMessageList] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [wentWrong, setWentWrong] = useState(false);
  const [unread, setUnread] = useState(0);
  const [unreadId, setUnreadId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(1);
  const [sendingMessage , SetSendingMessage] = useState(false);
  const [minimumUploadAltered, setMinimumUploadAltered] = useState(false);
  const [minimumUploadAlteredMessage, setMinimumUploadAlteredMessage] = useState('');
  const [messageFile, setMessageFile] = useState([]);
  const [report, setReport] = useState(null);
  const [fetchingMoreMessages, setFetchingMoreMessages] = useState(false);
  const [reportModal, setReportModal] = useState(false);



  useEffect(()=>{

    const ID = window.atob(window.atob(id));
    const fetchMessages=()=>{
      Axios.get(`${PROXMIN}/admin_get_report_chats/${ID}`, {headers: head()})
      .then(async(res)=>{
        if(!mounted.current) return;
        await setLoading(false);
        if(res.data.success){
          // if(res.data.data.messages.data && res.data.data.messages.data.length < 1){
          //   if(!mounted.current) return;
					// 	window.localStorage.clear();
            
          // }
          const tempMessage = res.data.data.messages
          tempMessage.data = tempMessage.data.reverse();
          await setMessageList(tempMessage);
          messageHolder.current = tempMessage;
          await setUnread(res.data.data.unread);
          next_url.current = tempMessage.next_page_url;
          setReport(res.data.data.report[0]);
          const firstNoRead = tempMessage.data.find(raw=> !Boolean(raw.isAdmin) && 
            (raw.read === null || raw.read.toString().indexOf(`xx_admin_xx`) === -1));
          if(firstNoRead){
            await setUnreadId(firstNoRead.id);
            markUnread();
          }
          const gotoUnread =  document.getElementById('new_messages');
					if(gotoUnread){
						gotoUnread.scrollIntoView();
						window.document.body.scrollTop = 0;
            window.document.documentElement.scrollTop = 0;
            setTimeout((()=>{
              gotoUnread.scrollIntoView();
            }),200)
					}else{
            const lastLine =  document.getElementById('last');
            if(lastLine){
              lastLine.scrollIntoView();
              setTimeout((()=>{
                lastLine.scrollIntoView();
              }),200)
            }
          }
          prepareSearchMore();
          
        }else{          
          if(!mounted.current) return;
          if(res.data.Error === 'Intruder'){
						if(!mounted.current) return;
						window.localStorage.clear();
            
          }
          await setMessageList({data:[]})
          await setWentWrong(true);
          await setLoading(false);
        }
      })
      .catch(err=>{
        if(!mounted.current) return;
          if(err.response && err.response.status === 500) mounted.current && setWentWrong(true);
          if(err.response && err.response.status === 404) mounted.current && setNotFound(true);

      })
    }

    const verifyId=()=>{
      if(window.atob(window.atob(id)) !== ID){
        setNotFound(true);
        return false;
      }
    }

    const markUnread =()=>{
      Axios.get(`${PROXMIN}/admin_mark_unread_report_chats/${ID}`, {headers:head()})
      .then(async(res)=>{
        if(res.data.success && res.data.data.status === 'marked'){
          // if(!mounted.current) return;          
          // console.log('successful');
        }
      })
      .catch(async(err)=>{
        // if(!mounted.current) return;
        // if(err.response) console.log(err.response);
      } )
    }

    const fetchMoreMessages=async()=>{
      if(fetchingMoreMessages) return;
      if(next_url.current){
        await setFetchingMoreMessages(true);
        Axios.get(`${next_url.current}`, {headers: head()})
        .then(async(res)=>{
          if(res.data.success){
            if(!mounted.current) return;
            const currentData = [...messageHolder.current.data];
            const incomingData = [...res.data.data.messages.data.reverse()];
            res.data.data.messages.data=[...incomingData,...currentData];
            await setMessageList(res.data.data.messages);         
            messageHolder.current = res.data.data.messages;
            next_url.current = res.data.data.messages.next_page_url
            scrollBox.current.scrollTop = 5;
            await setFetchingMoreMessages(false);

          }
        })
        .catch(async(err)=>{
          if(!mounted.current) return;
          // if(err) console.log(err);
          await setFetchingMoreMessages(false);
        })
      }
    }

    const scrollBoxEvent=async()=>{
      if(!scrollBox.current) return;
      if(scrollBox.current.scrollTop < 1){  
        await fetchMoreMessages();
      }
    }
    
    const prepareSearchMore=async()=>{
      if(!scrollBox.current) return;
      await scrollBox.current.addEventListener('scroll', scrollBoxEvent);
      scrollBox.current.scrollTop = 5;
    }

    if(loading && !messageList){
      verifyId();
      fetchMessages();
    }

    return()=>{
      scrollBox.current = window.document.getElementById('scrollBox');
      if(scrollBox.current) scrollBox.current.removeEventListener('scroll', scrollBoxEvent);
    }

  },[id, messageList, loading, fetchingMoreMessages]);

  

    const handleFileUploadChange=async(e)=>{
      e.preventDefault();
      const fileCollector = e.target.files;
      await setMinimumUploadAltered(false);
      if(fileCollector.length > 4){
          await setMinimumUploadAlteredMessage('You can only send maximum of Four files');
          await setMinimumUploadAltered(true);
          return;
      }
      const Temp = [];
      let storeSize=0;
      for (let i = 0; i < fileCollector.length; i++) {
          Temp.push(fileCollector[i]);
          // console.log(fileCollector[i].type)
          storeSize += fileCollector[i].size;
      }
      // console.log(storeSize);
      if(storeSize > 12582912){
          await setMinimumUploadAlteredMessage('File(s) too large, maximimum of 12MB is allowed');
          await setMinimumUploadAltered(true);
          return;
      }
      await setMessageFile(Temp);
      await setType(2)
  }

  const sendMessageFile=async(e)=>{
    e.preventDefault();
    if(sendingMessage) return;
    if(report && !Boolean(report.status)) return;
    const form = new FormData();
    if(messageFile.length > 0){
      for (let i = 0; i < messageFile.length; i++) {
        form.append(`pc_files[${i}]`, messageFile[i]);
      }
    }else{      
      await setType(1);
      return;
    }
    form.append('message', '');
    form.append('project_report_id', window.atob(window.atob(id)));
    const AUTH = head();
    AUTH['X-Socket-Id'] = window.Echo.socketId();
    SetSendingMessage(true);
    Axios.post(`${PROXY}/message_on_report`, form, {headers: AUTH})
    .then(async(res)=>{
      if(res.data.success){
      if(!mounted.current) return;
        const tempMessage= Object.assign({}, messageHolder.current)
        const tempData = [...tempMessage.data,...res.data.data];
        tempMessage.data=tempData;
        await setMessageList(tempMessage)
        messageHolder.current = tempMessage
        reference.current = null;
        await setMessageFile([]);
        await setType(1);
        mounted.current && SetSendingMessage(false);
        const lastLine =  document.getElementById('last');    
        if(lastLine){
          lastLine.scrollIntoView();
        }

      }else{
        if(res.data.Error === 'Intruder'){
          // console.log('intruder')
          window.localStorage.clear();
          
        }
      }
    })
    .catch(async(err)=>{
      if(!mounted.current) return;
      await setType(1);
      mounted.current && await SetSendingMessage(false);
      if(err.response && err.response.status === 500) mounted.current && setWentWrong(true);
      if(err.response && err.response.status === 404) mounted.current && setNotFound(true);

    })

  }

  const messageOnReport=async(e)=>{
    e.preventDefault();
    if(sendingMessage) return;
    if(report && !Boolean(report.status)) return;
    const message = window.document.getElementById('sendInput').value.trim();
    if(!message) return;        
    const form = new FormData();
    form.append('message', message);
    form.append('project_report_id', window.atob(window.atob(id)));
    form.append('pc_files[]','');
    const AUTH = head();
    AUTH['X-Socket-ID'] = window.Echo.socketId();
    SetSendingMessage(true);
    Axios.post(`${PROXY}/message_on_report`, form, {headers:AUTH})
    .then(async(res)=>{
      if(res.data.success){
        // console.log(res.data.data)
        if(!mounted.current) return;
        const tempMessage= Object.assign({}, messageHolder.current)
        const tempData = [...tempMessage.data,...res.data.data];
        tempMessage.data=tempData;
        window.document.getElementById('sendInput').value='';
        await setMessageList(tempMessage);
        messageHolder.current = tempMessage
        reference.current = null;
        mounted.current && SetSendingMessage(false);
        const lastLine =  document.getElementById('last');    
        if(lastLine){
          lastLine.scrollIntoView();
        }

      }else{
        if(res.data.Error === 'Intruder'){
          window.localStorage.clear();
          
          // console.log('intruder');
        }
      }

    })
    .catch(async(err)=>{
      mounted.current && await SetSendingMessage(false);
      if(err.response && err.response.status === 500) mounted.current && setWentWrong(true);
      if(err.response && err.response.status === 404) mounted.current && setNotFound(true);
    })
  }

  const deleteFile=(e)=>{
    e.preventDefault();
    // console.log(e.target)
    const go = window.confirm("Do you want to remove this file?");
    if(!go) return;
    const collector = [...messageFile];
    collector.splice(Number(e.target.id), 1);
    setMessageFile(collector);
    if(messageFile.length< 1) setType(1);
    // console.log(collector);
  }



  useEffect(()=>{
    mounted.current = true;

    const listenToNewMessages=async()=>{
      if(!window.Echo) return;
      await window.Echo.private(`adminReport.${admin.adminName}`)
      .listen('.newReportMessage', async(data)=>{
        const tempMessage= Object.assign({}, messageHolder.current);
        const tempData = [...tempMessage.data,...JSON.parse(data[0])];
        tempMessage.data=tempData;
        await setMessageList(tempMessage);
        messageHolder.current = tempMessage;
        const lastLine =  document.getElementById('last');    
        if(lastLine){
          lastLine.scrollIntoView();
        }
      })
    }

    staticPusherConfig();
    if(Boolean(admin)){
      listenToNewMessages();
    }
    
    return()=>{
      mounted.current = false;
    }
      // eslint-disable-next-line
  },[admin]);

  const closeReport=async(condition)=>{
    await setReportModal(condition); 
  }

  return(<div>
    {loading?
      <div className='d-flex justify-content-center'>
        <div className='w3-spin spinner w3-circle mt-2'></div>
      </div>:
      <div>
        {notFound?
          <div>
            Not found
          </div>:
          <div>
            {wentWrong?
              <div> 
                Something went wrong
              </div>:
              <div>
                <div className=" w3-card w3-round-xlarge position-relative bg-light mb-3"
                  style={{ height:'87vh' }}
                >
                <div className="card-header px-3 border-0 py-1">
                  <div className="d-flex  justify-content-between">
                    <div className='mx-auto'>
                      <Avatar alt="avatar1" className="img-fluid mr-2" style={ {width:'30px', height:'30px'} } 
                        src={(report && report.profilePic)? report.profilePic: 'noImage'}
                      />
                    </div>
                    <div className='mx-auto my-auto'>
                      <strong>
                        {(report && report.userName)? report.userName : ''}
                      </strong>
                    </div>
                    <div className='mx-auto'>
                      <button className='w3-button w3-light-grey w3-round-xxlarge' 
                        onClick={()=>{closeReport(true)}}
                      >
                        View Report
                      </button>
                    </div>
                    <div className="ml-auto my-auto text-primary">
                      <label>
                        <Attachment />
                        <input 
                          onChange={handleFileUploadChange}
                          className='d-none' accept='.png, .svg, .gif, .jpg, .3gp, .avi, .mpeg, .mp4, .mkv' 
                          multiple type='file' 
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div id='scrollBox' className="card mb-5" style={{height:'70vh', overflowY: 'auto'}} >
                  <div className="card-body">
                    <div>
                      {fetchingMoreMessages &&
                        <div className='text-center'>
                          <div className='w3-spin spinner w3-circle mt-2'></div>
                        </div>
                      }
                      {messageList && messageList.data &&  messageList.data.map((message) =>
                        <div key={message.id}>
                          <div  className='w3-container'>
                          {(unreadId && unreadId === message.id) &&
                              <small id='new_messages' className='w3-text-blue mb-4'>
                                {unread > 1 && <span> {unread} unread messages</span>}
                                {unread === 1 && <span>1 unread message</span>}
                              </small>
                            }
                          </div>
                          { (message.type === 0) &&
                            <Paper elevation={0} style={Boolean(message.isAdmin)? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
                              
                              
                                <div className='d-flex justify-content-between'>
                                  <div className='mr-2'>
                                    <small><strong>
                                      {Boolean(message.isAdmin)? `${message.adminName}`: `${message.origin}`}
                                    </strong></small>
                                  </div>
                                  <div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
                                </div>
                              
                              <Typography style={ {fontSize: '0.9rem'} }>
                                {message.message}
                              </Typography>
                            </Paper>
                          }
                          { (message.type === 1)&&
                            <Paper elevation={0} style={Boolean(message.isAdmin)? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
                              <div className='d-flex justify-content-between'>
                                <div className='mr-2'>
                                  <small><strong>
                                    {Boolean(message.isAdmin)? `${message.adminName}`: `${message.origin}`}
                                  </strong></small>
                                </div>
                                <div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
                              </div>
                              <Typography style={ {fontSize: '0.9rem'} }>
                                <img alt='noImage' src={`${message.message}`} width='150' height='150' />
                              </Typography>
                            </Paper>
                          }
                          { (message.type === 2) &&
                            <Paper elevation={0} style={Boolean(message.isAdmin)? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
                              <div className='d-flex justify-content-between'>
                                <div className='mr-2'>
                                  <small><strong>
                                    {Boolean(message.isAdmin)? `${message.adminName}`: `${message.origin}`}
                                  </strong></small>
                                </div>
                                <div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
                              </div>
                              <Typography style={ {fontSize: '0.9rem'} }>
                                <video width='150' height='150' controls>
                                  <source src={`${message.message}`} />
                                  Your device cannot play this video
                                </video>
                                {/*<iframe width='150' height='150' src={`${message.message}`}></iframe>*/}
                              </Typography>
                            </Paper>
                          }
                              
                          <div className="clearfix" style={{clear:'both'}}></div>
                        </div>
                      ) }
                        <div id="last"></div>
                      </div>
                    </div>
                </div>
                <div style={classes.chatInputDiv}>
                    {minimumUploadAltered ?
                      <div>
                        <small className='text-danger'>
                          {minimumUploadAlteredMessage}
                        </small>
                      </div>:      
                      <form>
                        {type === 1 ?
                          <Box className="d-flex">
                            <textarea id="sendInput"
                              className="form-control rounded-pill mr-1"
                              placeholder="your message here, it checks the report if first..." 
                              style={classes.chatInput}
                              maxLength='2000'
                            />
                            <button disabled={(report && Boolean(report.status))? false: true} 
                              className="btn w3-indigo text-white rounded-pill" onClick={messageOnReport}
                            >
                              <ChevronRight />
                            </button>
                          </Box>:
                          <Box style={{position: 'relative'}}>
                            <div className="d-flex justify-content-center" id="sendInput" style={classes.chatInput}>
                              {messageFile.length > 0 && messageFile.map((data, index)=>
                                <div className='mx-1 w3-round' key={index} style={{display: 'inline'}}>
                                  {data.type.includes('image')?
                                  <img alt='message' id={index} onClick={deleteFile}  width='40' height='40' src={URL.createObjectURL(data)} />:
                                  <video id={index} onClick={deleteFile} width='40' height='40' controls>
                                    <source src={URL.createObjectURL(data)} />
                                    Your device cannot play this video
                                  </video>
                                  }
                                </div>
                              )}
                              <button 
                                className=" btn customPrimaryBgColor text-white rounded-pill" 
                                onClick={sendMessageFile}
                                style={{position: 'absolute', right:0}}
                              >
                                <ChevronRight />
                              </button>
                            </div>
                          </Box>
                        }
                      </form>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
    }

  { reportModal &&  <ReportDetails report={report} open={reportModal} opener={closeReport} />}

  </div>)

}

const mapStateToProp=(state)=>{
  return{
    admin: state.admin
  }
}

// const mapDispatchToProps=(dispatch)=>{
//   return {

//   }
// }

export default connect(mapStateToProp)(ReportTicket);

const ReportDetails=({report, open, opener})=>{

  return(<div>
    <Modal
    show={open}
    onHide={()=>{opener(false)}}
    >
      <Modal.Body className="py-4">
        <div>
          <IconButton 
            variant="secondary"  
            onClick={()=>{opener(false)}} 
            style={classes.closeBtn}
          >
            X
          </IconButton>
        </div> 
        {report &&
          <div>
            <div className="d-flex justify-content-center my-2">              
              <Avatar alt='noImage' className="mr-2" 										
                src={report.profilePic? report.profilePic: 'noImage'} 
              />
            </div>
            <div className="d-flex justify-content-center">
              <div className="font-weight-bold">{report.userName}</div>                
            </div>
            <div className="d-flex justify-content-center py-2">
             <Typography variant="caption" color="secondary">
              <strong>Reason:</strong> {category[report.key-1]}
              </Typography>
            </div>
            <div className="d-flex justify-content-center py-2">
             <Typography variant="caption" color="secondary">
              <strong>Status:</strong> {Boolean(report.status)? 'ACTIVE': 'CLOSED'}
              </Typography>
            </div>
            <div>
              <div className="d-flex justify-content-center py-2">
                <strong>More Information</strong>
              </div>
              <Typography variant="body1" className=" d-flex justify-content-center py-2">
                {Boolean(report.report)? report.report: 'No additonal information added'}
              </Typography>
            </div>
            <div className="small d-flex justify-content-center"> Created on: {LUX_ZONE(report.created_at)}</div>
          </div>
        }         
      </Modal.Body>
    </Modal>
  </div>)

}