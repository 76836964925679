import React from 'react'
import Avatar from '@material-ui/core/Avatar';
// import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Axios from 'axios';
import { PROXMIN } from '../../global/constants';
import { head } from '../../global/gadget/gadget';
import { /*Typography,*/ CircularProgress, Paper, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { Cancel, Delete, Sync } from '@material-ui/icons';
import Filter from './FIlter';
import { connect } from 'react-redux';
import { professionalsList } from '../../global/action';


// const userList = [
// 	{ username: "username", fullname: "Full name", points: 45, image: "" },
// 	{ username: "username might be long", fullname: "Full name", points: 30, image: "" },
// 	{ username: "Another username", fullname: "another Full name", points: 25, image: "" },
// 	{ username: "username again", fullname: "One Full name", points: 16, image: "" },
// ]
const header = ["S/N", 'Picture', 'UserName', 'FirstName', 'LastName', 'Point', "Status", "Category", 'Cubcategory', 'Level', 'LevelDate', 'Options'];
const Professionals =({professionals, setProfessionals})=>{

	/** This is used to prevent data leakage */
	const mounted = React.useRef(true);
	const scrollBox = React.useRef(document.getElementById('scrollBox'));
	// const [professionals, setProfessionals] = React.useState(professionals_list);
	const [categories, setCategories] = React.useState(null);
	const [loadingPros, setLoadingPros] = React.useState(true);
	const [fetchinMorePros, setFetchingMorePros] = React.useState(false);

	React.useEffect(()=>{
		const fetchCategories=()=>{			
			Axios.get(`${PROXMIN}/get_category` , {headers: head()})
				.then(res=>{
					setCategories(res.data);
				})
				.catch(err=>{
					// console.log(err)
				})
		}

		if(!categories) fetchCategories();

		return ()=>{mounted.current = false}

	},[categories]);

	React.useEffect(()=>{
		const fetchProfessionals=()=>{			
			Axios.get(`${PROXMIN}/get_all_professionals` , {headers: head()})
				.then(res=>{
					// console.log(res.data.data);
					setProfessionals(res.data.data);
					setLoadingPros(false);
					// console.log(res.data.data);
				})
				.catch(err=>{
					// console.log(err);
					setLoadingPros(false);
				})
		}

		if(!professionals){
			fetchProfessionals();
		}else{
			setLoadingPros(false);
		}
	},[professionals, setProfessionals]);

	React.useEffect(()=>{
		if(!professionals || !professionals.next_page_url) return;
		/** This  methods fetches more professionals as virtual scrolling*/
		const fetchMoreProfessionals=()=>{
			if(!professionals.next_page_url) return;
			setFetchingMorePros(true);
			Axios.get(professionals.next_page_url, {headers: head()})
			.then(res=>{
				if(res.data.success){
					res.data.data.data = [...professionals.data, ...res.data.data.data];
					setProfessionals(res.data.data);
					setFetchingMorePros(false);
				}
			})
			.catch(err=>{
				setFetchingMorePros(false);
			})
		}

		/** The placeholder for event listerner */
		const virtualScroll =()=>{
			// console.log(scrollBox.current.scrollTop, scrollBox.current.clientHeight, scrollBox.current.scrollHeight);
			if(scrollBox.current.scrollTop+scrollBox.current.clientHeight >= scrollBox.current.scrollHeight){
				fetchMoreProfessionals();
			}
		}

		if(!scrollBox.current) scrollBox.current = document.getElementById('scrollBox');
		if(scrollBox.current) scrollBox.current.addEventListener('scroll', virtualScroll);
		return ()=>{if(scrollBox.current) scrollBox.current.removeEventListener('scroll',virtualScroll);}
	});



	/** This methods returns this category and subcatory name of a professional */
	const findCategory=(categoryId, subcategoryId)=>{
		if(!categories) return {category:'unknown', subcategory:'unknown'};
		let specification = categories.find(data=>data.id === categoryId);
		if(!specification) return {category:'unknown', subcategory:'unknown'};
		let subCat = specification.subcategory.find(data=>data.id === subcategoryId);
		if(!subCat) return {category: specification.name, subcategory: 'unknown'};
		return {category: specification.name, subcategory:subCat.name};
	}

	
	return (<div className='container mb-0 pb-0' >	
		<div className='w3-container'>		
		<div className="row">
			<div className="col-12">
				{/* <div className="d-flex justify-content-between mb-3">
					<h4>Professionals</h4>
					<div className="d-flex align-content-center">
						<div className="mr-2">
							<input type="search" className="form-control" placeholder="search" />
						</div>
						<div>
							<select className="custom-select">
								<option>Filter</option>
								<option>Points</option>
								<option>Category</option>
								<option>Sub Category</option>
								<option>Date</option>
							</select>
						</div>
					</div>
				</div> */}
				<Filter  category={categories} />
			</div>
		</div>

		<div className='row'>
			<div className='col-12 mb-1'>
				<Paper variant='outlined'  square
					className='d-flex justify-content-center py-1 mb-1 w3-light-grey'
					style={{marginLeft:'3px'}}		
				>	
					{loadingPros?
						<div>
							<CircularProgress  />
						</div>:
						<strong> 
							{professionals && typeof professionals.total === 'number' && professionals.total?
								`Total: ${professionals.total}`:
								"No professional fetched"
							}
						</strong>
					}	
				</Paper>
			</div>
		</div>
		</div>
		<div id="scrollBox" style={{ height:'70vh', overflowY:'auto', overflowX:'hidden'}}>
			{professionals && professionals.data ?
				<Table responsive striped hover size="sm">
					<thead>
						<tr>
							{header.map((data, index)=>
								<td key={index} >
									<strong>{data}</strong>
								</td>
							)}
						</tr>
					</thead>
					<tbody>
						{professionals.data.map((pro,index)=>
							<tr key={`${pro.userName}-${index}`}
								className='w3-hover-light-gray'
							>
								<td>{index+1}</td>
								<td>
								<Avatar 									
									src={pro.profilePic? pro.profilePic:'../../assets/avatar.png'} 
								/>
								</td>
								<td>{pro.userName}</td>
								<td>{pro.firstName}</td>
								<td>{pro.lastName}</td>
								<td>{pro.ratings}</td>
								<td>
									{!pro.active?
									 	<Cancel />:
										pro.active ===1?
										<Sync color='primary' />:
										<Delete color='secondary' />  
									}
								</td>
								<td>{findCategory(pro.category_id, pro.subcategory_id)['category']}</td>
								<td>{findCategory(pro.category_id, pro.subcategory_id)['subcategory']}</td>
								<td>{pro.level}</td>
								<td>{pro.levelDate}</td>
								<td>
									<Link style={{ textDecoration:'none' }}
									to={`/professional-operations/${pro.userName}/${window.btoa(window.btoa(pro.active))}`} 
										>
										<Button style={{ background:'#6EE5B2' }} disableElevation>View</Button>
									</Link>
								</td>
							</tr>
						)}
					</tbody>
				</Table>:
				null
			}
			<div className='d-flex justify-content-center'>
				{fetchinMorePros && <CircularProgress style={{ fontSize: '5px' }} />}
			</div>
		</div>
			
	</div>);
}


const mapStateToProps = (state) => {
  return {
    professionals: state.professionals,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProfessionals: (payload)=>dispatch(professionalsList(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (Professionals)