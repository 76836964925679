import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import Axios from 'axios';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Avatar, Box, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { PROXMIN } from '../../global/constants';
import { CHAT_LIKE_TIME, head, staticPusherConfig } from '../../global/gadget/gadget';



const classes = {
	userChatStyle: {
		float:'right',
		maxWidth: '85%',
		backgroundColor: '#F9FBFF',
		borderRadius: '15px 0 15px 15px',
	},
	otherChatStyle: {
		float:'left',
		maxWidth:'85%',
		borderRadius: '0 15px 15px 15px',
	},
	chatInput: {
		height: '50px',
	},
	chatInputDiv: {
		position: 'absolute',
		zIndex: '1',
		backgroundColor: '#F9FBFF',
		width: '100%',
		bottom: '0',
		padding: '7px 12px',
	}
}



const MessageUser=({user, condition})=>{

    const mounted = useRef(true);
    let scrollBox = useRef(window.document.getElementById('scrollBox'));
    const [fetchingMoreMessages, setFetchingMoreMessages] = useState(false);
    const [initialUnread, setInitialUnread] = useState(0);
    const [unreadId, setUnreadId] = useState(null);
    const [messageList, setMessageList] = useState({});
    const [minimumUploadAltered, setMinimumUploadAltered] = useState(false);
    const [minimumUploadAlteredMessage, setMinimumUploadAlteredMessage] = useState('');
    const [messageFile, setMessageFile] = useState([]);
    // const [conversation_id, SetConversation_id] = useState(null);
    const [parentHeight, setParentHeight] = useState(( window.screen.width > 450)? '500px':`${window.innerHeight-100}px`);
    const [parentWidth, setParentWidth] = useState((window.innerWidth > 450)? '400px':'100%',);
    const [childHeight, setChildHeight] = useState(( window.innerWidth > 450)? '400px':`${window.innerHeight-200}px`);
    const [type, setType] = useState(1);
    const [sendingMessage, SetSendingMessage] = useState(false);
    const [userDetails, setUserDetails] = useState(null);




    const loadMessages=async()=>{
		if(!fetchingMoreMessages){
      await setFetchingMoreMessages(true);
			Axios.get(`${PROXMIN}/get_admin_user_message/${user}`, {headers:head()})
			.then(async(res)=>{
				if(res.data.success){
					if(!mounted.current) return;
					res.data.data.messages.data = res.data.data.messages.data.reverse();
					const temp = [...res.data.data.messages.data];
          await setInitialUnread(res.data.data.unread);
          await setUserDetails(res.data.data.participant);
					const unread = temp.find(data=> data.read === 0 && data.isAdmin === 0);
					if(unread){
            if(!mounted.current) return;
            await setUnreadId(unread.id)
						if(mounted.current) markUnread();
					}
					if(!mounted.current) return;				
          await setMessageList(res.data.data.messages);                    
          await setFetchingMoreMessages(false);
          prepareSearchMore();
					const gotoUnread = await document.getElementById('new_messages');
					// console.log(gotoUnread);
					if(gotoUnread){
						gotoUnread.scrollIntoView();
						window.document.body.scrollTop = 0;
						window.document.documentElement.scrollTop = 0;
						return;
					}else{
            const lastLine =  document.getElementById('last');    
            if(lastLine){
              lastLine.scrollIntoView();
            }
						if(scrollBox.current){
              scrollBox.current.scrollTop = scrollBox.current.scrollHeight - scrollBox.current.clientHeight;
						}else{
							scrollBox.current= await window.document.getElementById('scrollBox');
							if(!scrollBox.current) return;
							scrollBox.current.scrollTop = scrollBox.scrollHeight - scrollBox.clientHeight;
							return;
						}
					}
				}else{
          if(res.data.Error === "Not_found"){
              await setMessageList({data:[]})
              await setFetchingMoreMessages(false);
              return;
          }
				}
			})
			.catch(async(err)=>{
				if(!mounted.current) return;
				// if(err) console.log(err);
				await setFetchingMoreMessages(false);
			})

		}
	}
	const markUnread =async()=>{
		Axios.get(`${PROXMIN}/mark_admin_unread_messages/${user}`, {headers:head()})
		.then(async(res)=>{
			if(res.data.success && res.data.data.status === 'marked'){
				if(!mounted.current) return;
				// console.log('successful');
			}
		})
		.catch(async(err)=>{
			if(!mounted.current) return;
			// if(err.response) console.log(err.response);
		} )
	}

	const fetchMoreMessages=async()=>{
		if(fetchingMoreMessages) return;
		if(messageList.next_page_url){
			await setFetchingMoreMessages(true);
			Axios.get(`${messageList.next_page_url}`, {headers: head()})
			.then(async(res)=>{
				if(res.data.success){
					if(!mounted.current) return;
					const currentData = [...messageList.data];
					const incomingData = [...res.data.data.messages.data.reverse()];
					res.data.data.messages.data=[...incomingData,...currentData];
					await setMessageList(res.data.data.messages)
					scrollBox.scrollTop = 5;
					await setFetchingMoreMessages(false);

				}
			})
			.catch(async(err)=>{
				if(!mounted.current) return;
				// if(err) console.log(err);
				await setFetchingMoreMessages(false);
			})
		}
	}

	/*const listenToNewMessages=async()=>{
        if(!window.Echo) return;
        await window.Echo.private(`pc.${conversation_id}`)
        .listen('.newMessage', async(data)=>{
			// console.log(JSON.parse(data[0]), 'from chat');
			const tempMessage= Object.assign({}, messageList)
			const tempData = [...tempMessage.data,...JSON.parse(data[0])];
			tempMessage.data=tempData;
			await setMessageList(tempMessage)
			// console.log(messageList);
			// const dupStateChats = Object.assign({}, this.props.initialPrivateChats);
			// const chat = dupStateChats.data.find(data=>data.conversation_id === conversation_id);
			// if(chat.unread_count < 1) return;
			// chat.unread_count = 0;
			// const INDEX = dupStateChats.data.findIndex(data=>data.conversation_id === conversation_id);
			// dupStateChats.data.splice(INDEX,1,chat);
			// console.log(dupStateChats);
			// this.props.loadPrivateChats(dupStateChats)
        })
    }*/


    const handleFileUploadChange=async(e)=>{
        e.preventDefault();
        const fileCollector = e.target.files;
        await setMinimumUploadAltered(false);
        if(fileCollector.length > 4){
            await setMinimumUploadAlteredMessage('You can only send maximum of Four files');
            await setMinimumUploadAltered(true);
            return;
        }
        const Temp = [];
        let storeSize=0;
        for (let i = 0; i < fileCollector.length; i++) {
            Temp.push(fileCollector[i]);
            // console.log(fileCollector[i].type)
            storeSize += fileCollector[i].size;
        }
        // console.log(storeSize);
        if(storeSize > 12582912){
          await setMinimumUploadAlteredMessage('File(s) too large, maximimum of 12MB is allowed');
          await setMinimumUploadAltered(true);
          return;
        }
        await setMessageFile(Temp);
        await setType(2)
    }

    const sendMessageFile=async(e)=>{
      e.preventDefault();
      if(sendingMessage) return;
      const form = new FormData();
      if(messageFile.length > 0){
        for (let i = 0; i < messageFile.length; i++) {
          form.append(`pc_files[${i}]`, messageFile[i]);
        }
      } else{      
        await setType(1);
        return;
      }
      form.append('message', '');
		form.append('receiver', user);
		const AUTH = head();
    AUTH['X-Socket-Id'] = window.Echo.socketId();
    SetSendingMessage(true);
		Axios.post(`${PROXMIN}/send_participant_message`, form, {headers: AUTH})
		.then(async(res)=>{
            if(res.data.success){
				if(!mounted.current) return;
                const tempMessage= Object.assign({}, messageList)
                const tempData = [...tempMessage.data,...res.data.data];
                tempMessage.data=tempData;
                await setMessageList(tempMessage)
                await setMessageFile([]);
                await setType(1);
                mounted.current && SetSendingMessage(false);
                if(scrollBox.current) scrollBox.current.scrollTop = scrollBox.current.scrollHeight - scrollBox.current.clientHeight;

            }else{
                if(res.data.Error === 'Intruder'){

                }
            }

        })
        .catch(async(err)=>{
            if(!mounted.current) return;
            await setType(1);
            mounted.current && await SetSendingMessage(false);

        })

    }

    const sendPrivateMessage=async(e)=>{
      e.preventDefault();
      if(sendingMessage) return;
      const message = window.document.getElementById('sendInput').value.trim();
      if(!message) return;        
      const form = new FormData();
      form.append('message', message);
      form.append('receiver', user);
      form.append('pc_files[]','');
      const AUTH = head();
      AUTH['X-Socket-ID'] = window.Echo.socketId();
      SetSendingMessage(true);
      Axios.post(`${PROXMIN}/send_participant_message`, form, {headers:AUTH})
      .then(async(res)=>{
        if(res.data.success){
            // console.log(res.data.data)
          if(!mounted.current) return;
          const tempMessage= Object.assign({}, messageList)
          const tempData = [...tempMessage.data,...res.data.data];
          tempMessage.data=tempData;
          window.document.getElementById('sendInput').value='';
          await setMessageList(tempMessage);
          mounted.current && SetSendingMessage(false);
          if(scrollBox.current) scrollBox.current.scrollTop = scrollBox.current.scrollHeight - scrollBox.current.clientHeight;

        }else{
          if(res.data.Error === 'Intruder'){
              
          }
        }

      })
      .catch(async(err)=>{
        mounted.current && await SetSendingMessage(false);
      })
    }

    const deleteFile=(e)=>{
      e.preventDefault();
      // console.log(e.target)
      const go = window.confirm("Do you want to remove this file?");
      if(!go) return;
      const collector = [...messageFile];
      collector.splice(Number(e.target.id), 1);
      if(collector.length < 1){
			setType(1);
			const uploader = document.getElementById('filer');
			uploader.value = null;
		}
    setMessageFile(collector);
	}

	const scrollBoxEvent=()=>{
    console.log('scrolling');
    if(!scrollBox.current) scrollBox.current = window.document.getElementById('scrollBox');
		if(scrollBox.current ){
      console.log('condition');
      if( scrollBox.current.scrollTop < 1){
        fetchMoreMessages();
      }
		}
	}

	const prepareSearchMore=()=>{
    if(!scrollBox.current) scrollBox.current = window.document.getElementById('scrollBox');
		if(scrollBox.current){
      scrollBox.current.addEventListener('scroll', scrollBoxEvent);
    } 
	}

    useEffect(()=>{
      mounted.current = true;      
      // console.log(scrollBox);
      loadMessages();
      staticPusherConfig();
      
      return()=>{
        mounted.current = false;
        console.log('vvv');
        if(scrollBox.current){
          scrollBox.current.removeEventListener('scroll', scrollBoxEvent);
        } 
          
      } 
        // eslint-disable-next-line
    },[user]);

    useEffect(()=>{
        const checkWidth=()=>{
            if(window.innerWidth > 450){
                setChildHeight('400px');
                setParentHeight('500px');
                setParentWidth('400px')
                return;
            }
            else{
                setChildHeight(`${window.innerHeight-200}px`);
                setParentHeight(`${window.innerHeight-100}px`);
                setParentWidth('100%')
                return;
            }
        }

        window.addEventListener('resize', checkWidth);
        return ()=>window.removeEventListener('resize', checkWidth);
    },[])

    return(<React.Fragment>
        <div className=" w3-card w3-round-xlarge position-relative bg-light mb-3"
            style={{
                width:parentWidth,
                height:parentHeight,
            }}
        >
            <div className="card-header px-3 border-0 py-1">
                <div className="d-flex">
                    <div className="d-flex">
                        <Avatar alt="avatar1" className="img-fluid mr-2" style={ {width:'30px', height:'30px'} } 
                          src={
                            (userDetails && userDetails.profilePic)?
                            userDetails.profilePic: 'noImage'
                          }
                            
                        />
                    </div>
                    <div className="ml-auto my-auto text-primary">
                        <label>
                            <AttachmentIcon />
                            <input 
                                onChange={handleFileUploadChange}
                                className='d-none' accept='.png, .svg, .gif, .jpg, .3gp, .avi, .mpeg, .mp4, .mkv' 
                                multiple type='file'  id='filer'
                            />
                        </label>

                        <Button onClick={()=>condition(false)}>
                            <CloseIcon />
                        </Button>
                    </div>
                </div>
            </div>
            <div id='scrollBox' className="card mb-5" 
                style={{
                    overflowY: 'auto',
                    height:childHeight,
                }}
            >
                <div className="card-body">
                    <div>
                    {fetchingMoreMessages &&
                        <div className='text-center'>
                            <div className='w3-spin spinner w3-circle mt-2'></div>
                        </div>
                    }
                        {messageList.data &&  messageList.data.map((message) =>
                            <div key={message.id} >
                                <div  className='w3-container'>
                                    {(unreadId && unreadId === message.id) &&
                                      <small id='new_messages' className='w3-text-blue mb-4'>
                                        {initialUnread > 1? `${initialUnread} unread messages`:`${initialUnread} unread message`}
                                      </small>
                                    }
                                </div>
                                { (message.type === 0) &&
                                    <Paper elevation={0} style={(Boolean(message.isAdmin)) ? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
                                        <div className="d-flex justify-content-between">
                                          {Boolean(message.isAdmin) && 
                                            <div>
                                              <strong><small>
                                                {message.adminName}
                                              </small></strong>
                                            </div>
                                          }
                                          <div>
                                            <small>{CHAT_LIKE_TIME(message.created_at)}</small>
                                          </div>
                                        </div>
                                        <Typography style={ {fontSize: '0.9rem'} }>
                                            {message.message}
                                        </Typography>
                                    </Paper>
                                }
                                { (message.type === 1)&&
                                    <Paper elevation={0} style={(Boolean(message.isAdmin)) ? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
                                        <div className="d-flex justify-content-between">
                                          {Boolean(message.isAdmin) && 
                                            <div>
                                              <strong><small>
                                                {message.adminName}
                                              </small></strong>
                                            </div>
                                          }
                                          <div>
                                            <small>{CHAT_LIKE_TIME(message.created_at)}</small>
                                          </div>
                                        </div>
                                        <Typography style={ {fontSize: '0.9rem'} }>
                                            <img alt='noImage' src={`${message.message}`} width='150' height='150' />
                                        </Typography>
                                    </Paper>
                                }
                                { (message.type === 2) &&
                                    <Paper elevation={0} style={(Boolean(message.isAdmin)) ? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
                                        <div className="d-flex justify-content-between">
                                          {Boolean(message.isAdmin) && 
                                            <div>
                                              <strong><small>
                                                {message.adminName}
                                              </small></strong>
                                            </div>
                                          }
                                          <div>
                                            <small>{CHAT_LIKE_TIME(message.created_at)}</small>
                                          </div>
                                        </div>
                                        <Typography style={ {fontSize: '0.9rem'} }>
                                            <video width='150' height='150' controls>
                                                <source src={`${message.message}`} />
                                                Your device cannot play this video
                                            </video>
                                            {/*<iframe width='150' height='150' src={`${message.message}`}></iframe>*/}
                                        </Typography>
                                    </Paper>
                                }
                                
                                <div className="clearfix" style={{clear:'both'}}></div>
                            </div>
                        ) }
                        <div id="last"></div>
                    </div>
                </div>
            </div>
            <div style={classes.chatInputDiv}>
                {minimumUploadAltered ?
                    <div>
                        <small className='text-danger'>
                            {minimumUploadAlteredMessage}
                        </small>
                    </div>:
                
                    <form>
                    {type === 1 ?
                        <Box className="d-flex">
                            <textarea id="sendInput"
                                className="form-control rounded-pill mr-1"
                                placeholder="your message here..." 
                                style={classes.chatInput}
                                maxLength='1000'


                            />
                            <button className="btn customPrimaryBgColor text-white rounded-pill" onClick={sendPrivateMessage}>
                                <ChevronRightIcon />
                            </button>
                        </Box>:
                        <Box style={{position: 'relative'}}>
                            <div className="d-flex justify-content-center" id="sendInput" style={classes.chatInput}>
                                {messageFile.length > 0 && messageFile.map((data, index)=>
                                    <div className='mx-1 w3-round' key={index} style={{display: 'inline'}}>
                                        {data.type.includes('image')?
                                        <img alt='message' id={index} onClick={deleteFile}  width='40' height='40' src={URL.createObjectURL(data)} />:
                                        <video id={index} onClick={deleteFile} width='40' height='40' controls>
                                            <source src={URL.createObjectURL(data)} />
                                            Your device cannot play this video
                                        </video>
                                        }
                                    </div>
                                )}
                                <button 
                                    className=" btn customPrimaryBgColor text-white rounded-pill" 
                                    onClick={sendMessageFile}
                                    style={{position: 'absolute', right:0}}
                                >
                                    <ChevronRightIcon />
                                </button>
                            </div>
                        </Box>
                    }
                    </form>
                }
            </div>
        </div>
    </React.Fragment> )

}

const mapStateToProps=(state)=>{
	return{
        admin: state.admin,
	}
}

// const mapDispatchToProps=(dispatch)=>{
//     return{
//     }
// }

export default connect(mapStateToProps)(MessageUser);