import React from 'react'
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import PeopleIcon from '@material-ui/icons/People';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { PROXMIN } from '../../global/constants';
import Axios from 'axios';
import { head } from '../../global/gadget/gadget';


/** Placeholder for stats mapping */
const	state = {
		topList: [
			{ title: "Users per day", key:"day", image: "", count: 12, color: 'primary' },
			{ title: "Users per week", key:"week", image: "", count: 52, color: 'secondary' },
			{ title: "Users per month", key:"month", image: "", count: 1902, color: 'primary' },
			// { title: "Users per location", key:"", image: "", count: 102 },
			{ title: "Total USers", key:"total", image: "", count: 102, color: 'secondary' },
			{ title: "Verified Users", key:"verified", image: "", count: 102, color: 'primary' },
			{ title: "Unverified Users", key:"unverified", image: "", count: 102, color: 'secondary' },
		],
		completedProjects: [
			{ username: "Username", image: "", status: 'completed', time: 'just now' },
			{ username: "Dorathy", image: "", status: 'completed', time: '2 days ago' },
			{ username: "Laycon", image: "", status: 'completed', time: '10 years ago' },
		],
		pendingProjects: [
			{ username: "Username", image: "", status: 'pending', time: '01:40' },
			{ username: "Username", image: "", status: 'pending', time: 'Out of time' },
			{ username: "Username", image: "", status: 'pending', time: '2days' },
		],
		chartList: [
			{ title: "Users per day", image: "", count: 12 },
			{ title: "Users per week", image: "", count: 52 },
			{ title: "Users per month", image: "", count: 1902 },
		],
		medilleryData: [
			{ title: "Users per day", image: "", count: 12 },
			{ title: "Users per week", image: "", count: 52 },
		]
}

const Home =()=>{

	const mounted = React.useRef(true);
	const [ stats, setStats] = React.useState(null);
	const [ statsError, setStatsError] = React.useState(false);
	const [selectedIndex, setSelectedIndex] = React.useState(1);

	React.useEffect(()=>{
		const fetchStats=()=>{
			Axios.get(`${PROXMIN}/basic_stats`, {headers: head()})
			.then(res=>{
				if(!mounted.current) return;
				if(res.data.success){
					setStats(res.data.data);
					console.log(res.data.data);					
					if(statsError) setStatsError(false);

				}else{
					setStatsError(true);
					
				}
			})
			.catch(err=>{
				console.log(err);
				
				if(mounted.current) setStatsError(true);

			})
		};

		if(!stats){
			fetchStats();
		}


		return ()=>{mounted.current = false};
	}, [statsError, stats])

	const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  	};

		
	return (<div className="container-fluid">
		<div className="row">
			<div className="col-12">
				<h5>Dashboard</h5>
			</div>
		</div>
		<div className="row mb-3">
			{ state.topList.map((item, index) => (
				<div key={item.key} className="col-md-3 col-lg-2 col-xl-2 col-6 mb-2">
					<Card>
						<CardContent className="card-body text-center">
							<Typography variant="body2">{ item.title }</Typography>
							<div className="d-flex justify-content-center my-2">
							<Fab color={item.color} aria-label="add">
								<PeopleIcon />
							</Fab>
								{/*<Avatar src="../../assets/avatar.png" className="mr-2" />*/}
							</div>
							{stats && (typeof stats[item.key] === 'number')?
								<h4>{ stats[item.key] }</h4>:
								<Typography variant="h6">Can't fetch</Typography>
							}
						</CardContent>
					</Card>
				</div>
			)) }
		</div>
		<div className="row mb-3">
			<div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-2">
				<Card>
					<CardContent>
						<Typography variant="body1" className="font-weight-bold">Completed Projects</Typography>
						{ state.completedProjects.map((item, index) => (
						 <ListItem key={index} button selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index)}>
			            <ListItemAvatar>
			              <Avatar
			                alt={item.username}
			                src={`../../assets/avatar.png`}
			              />
			            </ListItemAvatar>
			            <ListItemText id={item.username} primary={ item.username } secondary={ item.time } />
			          </ListItem>
						)) }
					</CardContent>
				</Card>
			</div>
			<div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-2">
				<Card>
					<CardContent>
						<Typography variant="body1" className="font-weight-bold">Pending Projects</Typography>
						{ state.pendingProjects.map((item, index) => (
						 <ListItem key={index} button selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index)}>
			            <ListItemAvatar>
			              <Avatar
			                alt={item.username}
			                src={`../../assets/avatar.png`}
			              />
			            </ListItemAvatar>
			            <ListItemText id={item.username} primary={ item.username } secondary={ item.time } />
			          </ListItem>
						)) }
					</CardContent>
				</Card>
			</div>
			<div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-2">
				<Card>
					<CardContent>
						<Typography variant="body1" className="font-weight-bold">Statistics</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Typography variant="caption">Completed Projects</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Typography variant="caption">Pending Project</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</div>
		</div>
		<div className="row">
			{ state.medilleryData.map((item, index) => (
				<div key={index} className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
					<Card>
						<CardContent className="text-center">
							<Typography variant="body2">{ item.title }</Typography>
							<div className="d-flex justify-content-center my-2">
								<Avatar src="../../assets/avatar.png" className="mr-2" />
							</div>
							<Typography variant="h6">{ item.count }+</Typography>
						</CardContent>
					</Card>
				</div>
			)) }
		</div>
		
	</div>);
}

export default Home