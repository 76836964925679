import { ADMIN, INTENDED_PAGE, PROFESSIONALS_LIST,
   SUSPENDED_LOAD, LOAD_PRIVATE_CHATS, LOAD_PC_DETAIL_CHANGES,
   LOAD_DESKTOP_PRIVATE_CHATS, SOME_PAGE_NOT_FOUND, MARK_SELECTED_CHAT
   
} from "../constants";


const initialState={
  admin: null,
  intendedPage: '/',
  suspendedLoad: null,
  professionals: null,
  initialPrivateChats: null,
  desktopInitialPCs: null,
  changedPcDetail: false,
  somePageNotFound: false,
  markSelectedChat: null,

}


function rootReducer(state= initialState, action){
  if(action.type === ADMIN){
    return Object.assign(
      {}, 
      state, 
      {admin: action.payload}
    );
  }else if(action.type === INTENDED_PAGE){
    return Object.assign(
      {}, 
      state, 
      {intendedPage: action.payload}
    );
  }
  else if(action.type === SUSPENDED_LOAD){
    return Object.assign(
      {}, 
      state, 
      {suspendedLoad: action.payload}
    );
  }
  else if(action.type === PROFESSIONALS_LIST){
    return Object.assign(
      {}, 
      state, 
      {professionals: action.payload}
    );
  }    else if(action.type === LOAD_PRIVATE_CHATS){
    return Object.assign(
        {},
        state,
        {initialPrivateChats: action.payload}
    )
}
else if(action.type === LOAD_DESKTOP_PRIVATE_CHATS){
    return Object.assign(
        {},
        state,
        {desktopInitialPCs: action.payload}
    )
}
else if(action.type === LOAD_PC_DETAIL_CHANGES){
    return Object.assign(
        {},
        state,
        {changedPcDetail: action.payload}
    )
}
else if(action.type === SOME_PAGE_NOT_FOUND){
    return Object.assign(
        {},
        state,
        {somePageNotFound: action.payload}
    )
}
else if(action.type === MARK_SELECTED_CHAT){
    return Object.assign(
        {},
        state,
        {markSelectedChat: action.payload}
     )
}


  return state;
};

export default rootReducer;