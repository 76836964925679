import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Actions from './Actions';
import AllDoneFiles from './AllDoneFiles';
import AllPRojects from './AllProjects';
import Clients from './Clients';
import ProjectChat from './ProjectChat';
import SingleClientDoneFiles from './SingleClientDoneFiles';
import SingleClientProjects from './SingleClientProjects';





const Operations=({id, status})=>{

  const [tabKey, setTabKey] = useState('actions');
  const [selectedUser, setSelectedUser] = useState(null);

  const setActiveKey=(key)=>{
    setTabKey(key)
  }

  const modify=async(key, user)=>{
    await setSelectedUser(user);
    await setTabKey(key);
  }

  return(<div>
    <Tabs  
      transition={false} id="noanim-tab-example"
      activeKey={tabKey}
      onSelect={setActiveKey}

    >
      <Tab eventKey="actions" title="Actions">
      {(tabKey === 'actions') &&
        <Actions id={id} status={status} modify={modify} />
      }
      </Tab>
      <Tab eventKey="clients" title="Clients">
        {(tabKey === 'clients') && <Clients id={id} modify={modify} />}
      </Tab>
      <Tab eventKey="projects" title="Projects"> 
        {(tabKey === 'projects') && <AllPRojects id={id}  modify={modify} />}
      </Tab>
      <Tab eventKey='singleClientProjects' title='Unique-projects'>
        {(tabKey === 'singleClientProjects') &&
         <SingleClientProjects id={id} user={selectedUser} modify={modify}/>
        }
      </Tab>
      <Tab eventKey="doneFiles" title="Projects-files"> 
        { (tabKey === 'doneFiles') && <AllDoneFiles id={id} modify={modify} />}
      </Tab>
      <Tab eventKey='singleClientDOneFiles'  title='Unique-projects-files'>
        {(tabKey === 'singleClientDOneFiles') &&
         <SingleClientDoneFiles id={id} user={selectedUser} />
        }
      </Tab>
      <Tab eventKey='projectChat' title='Project-chat'>
        {(tabKey === 'projectChat') && 
          <ProjectChat id={selectedUser}  modify={modify} />
        }
      </Tab>
    </Tabs>

  </div>)
}

export default Operations;