import React, {useEffect, useRef, useState} from 'react';
import { Avatar } from '@material-ui/core';
import Axios from 'axios';
import { connect } from 'react-redux';
import { loadPrivateChats } from '../../global/action';
import { CHAT_LIKE_TIME, head, staticPusherConfig } from '../../global/gadget/gadget';
import { PROXMIN } from '../../global/constants';
import { useHistory } from 'react-router-dom';

const avatarStyle = {
	height: 35,
	width: 35
}

const quitRequest = Axios.CancelToken.source();

const ChatList =({admin,chats, markSelectedChat,loadChats})=>{
	const timerHolder = useRef(null)
	const scrollChat = useRef(window.document.getElementById('scrollChat'));
	const mounted = useRef(true);

	const history = useHistory();

	// const [chats, setChats] = useState(null);
	const [fetching_chats, setFetching_chats] = useState(false);
	const [fetching_more_chats, setFetching_more_chats] = useState(false);
	const [selectedMessage, setSelectedMessage] = useState(null);
	const [searchKey, setSearchKey] = useState(null);
	const [chatReserve, setChatReserve] = useState(null);


	useEffect(()=>{

		const getChats=async()=>{
			if(fetching_chats) return;
			await setFetching_chats(true);          
			Axios.get(`${PROXMIN}/get_admin_user_chat_lists`, {headers:head(), cancelToken: quitRequest.token})
			.then(async(res)=>{
				if(res.data.success){
					loadChats(res.data.data);
					setChatReserve(res.data.data);
				}
				// if(!mounted.current) return;		
				await setFetching_chats(false);
			})
			.catch(async(err)=>{
				if(!mounted.current) return;
				// if(err) console.log(err)
				await setFetching_chats(false);	
			})
		}	

		if(!chats){
			getChats();
		}

		return ()=>{ mounted.current = false;}
	},[chats, fetching_chats, loadChats])

	useEffect(()=>{	
		if(!chats || !chats.next_page_url || fetching_chats) return;

		const getMoreChats=async()=>{
			if(chats.next_page_url && !setFetching_more_chats){
				setFetching_more_chats(true);
				Axios.get(`${chats.next_page_url}`, {headers:head(), cancelToken: quitRequest.token})
				.then(async(res)=>{
					if(res.data.success){
						
						/** Collect the present array of data rendering */
						const tempChats = [...chats.data];
						/** Collect incoming data of the next page */
						const incomingData = [...res.data.data.data];
						/** Join them add assign them back to the incoming data array alone to 
						 * give room for the next page url if items remain */
						res.data.data.data = [...tempChats,...incomingData];
						// await ({privateChats: res.data.data});
						loadChats(res.data.data);
						setFetching_more_chats(false);
					}
				})
				.catch(async(err)=>{
					if(!mounted.current) return;
					// if(err) console.log(err)
					setFetching_more_chats(false);		
				})
			}
		}

		const putToVirtual=()=>{
			if(scrollChat.current.scrollHeight <= scrollChat.current.clientHeight + scrollChat.current.scrollTop){
				getMoreChats();
			}
		}

		if(!scrollChat.current) scrollChat.current = window.document.getElementById('scrollChat');
		if(scrollChat.current) scrollChat.addEventListener('scroll', putToVirtual);

		return ()=>{ if(scrollChat.current) scrollChat.removeEventListener('scroll', putToVirtual);}
	})

	useEffect(()=>{
		setSelectedMessage(markSelectedChat)
		const dom = document.getElementById(`${markSelectedChat}`);
		if(!dom) return;
		dom.style.backgroundColor = '#44e0ff';
		dom.style.color = 'black';

	},[markSelectedChat])


	

	useEffect(()=>{
		const searchBox = document.getElementById('searchBox').value.trim();
		
		const getSearchedChats=async()=>{
			if(!searchBox)return;			
			setFetching_chats(true);
			Axios.get(`${PROXMIN}/get_searched_admin_user_chats/${searchKey}`,
			 {headers: head(), cancelToken: quitRequest.token})
			.then(async(res)=>{
				if(res.data.success){
					if(!mounted) return;
					await loadChats(res.data.data);
					await setFetching_chats(false);
				}
			})
			.catch(async(err)=>{
				if(!mounted.current) return;
				// if(err) console.log(err);
				await setFetching_chats(false);
			})
		}

		if(searchKey){
			timerHolder.current = setTimeout(getSearchedChats, 1500);
		}


	},[searchKey, loadChats])

	

	
	useEffect(()=>{

		const listenToNewMessages=()=>{
			window.Echo.private(`adminChat.${admin.adminName}`)
			.listen('.newChat', (data)=>{
				const incomingChat = JSON.parse(data[0])[0];
				const dupeChat = Object.assign({}, chats);
				const currentChat = [...chats.data]
				const exists = chats.data.findIndex(data=>data.conversation_id === incomingChat.conversation_id);
				// console.log(exists)
				if(exists >-1){
					currentChat.splice(exists,1);
					// console.log(currentChat, 'after splicing from side');
					currentChat.unshift(incomingChat);
					// console.log(currentChat, 'after unshift from side');
				}
				else if(exists === -1){
					currentChat.unshift(incomingChat);
				}
				dupeChat.data = currentChat;
				loadChats(dupeChat);
			})
		}

		if( admin && admin.adminName){
			staticPusherConfig();
			listenToNewMessages();
		}


	},[admin, chats, loadChats])


	const markAndNavigate=(conversationId)=>{
		try {
			if(selectedMessage){
				const formalSelectedMessage= document.getElementById(`${selectedMessage}`);
				formalSelectedMessage.style.backgroundColor = 'white';
				formalSelectedMessage.style.color = 'black';
	
			}
	
			setSelectedMessage(conversationId);
			// const encodedConversationId = await window.btoa(conversationId);
			const dom = document.getElementById(`${selectedMessage}`);
			dom.style.backgroundColor = '#44e0ff';
			dom.style.color = 'black';
			const dupChats = Object.assign({}, chats);
			const chat = dupChats.data.find(data=>data.conversation_id === conversationId);
			if(chat &&chat.unread_count > 0){
				const INDEX = dupChats.data.findIndex(data=>data.conversation_id === conversationId);
				chat.unread_count = 0;
				dupChats.data.splice(INDEX,1,chat);
				// console.log(dupChats);
				loadChats(dupChats);				
				history.push(`/inbox/${window.btoa(window.btoa(conversationId))}`);
			}else{
				history.push(`/inbox/${window.btoa(window.btoa(conversationId))}`);
			}
		} catch (e) {
			// console.log(e)
		}


	}

	const processSearch=async(e)=>{
		const val = e.target.value.trim();
		if(timerHolder.current) clearTimeout(timerHolder.current)
		if(!val){
			loadChats(chatReserve);
		}		
		await setSearchKey(val)
	}
	

	return (
		<nav className="mx-0 h-100">
		<div className="px-2 py-1" style={{ backgroundColor: "#F0F1F2"}}>
			<input id='searchBox' type="text" 
				onKeyUp={processSearch} 
				className="form-control rounded-pill" 
				placeholder="search" 
			/>
		</div>
		<div id='scrollChat' className="small pt-3 px-2 pb-5 h-100" style={{ overflowY: 'auto'}}>
			{ fetching_chats &&
				<div className='d-flex justify-content-center'>
					<div className='w3-spin spinner w3-circle mt-2'> {Number(fetching_chats)}</div>
				</div>
			}
			{chats && chats.data &&
				<div>
					{(chats.data.length > 0)?
						<div>
							{chats.data.map((chat, index) =>
								<div key={chat.id} className="d-flex mb-2 w3-hover-pale-blue" id={chat.conversation_id} 
									onClick={()=>markAndNavigate(chat.userName)}
								>
								<div>
										<Avatar alt="avatar1" className="img-fluid mr-2"
											src={chat.profilePic?
												chat.profilePic:'noImage'}
												style={ avatarStyle }
											/> 
																				
									</div>
									<div className="d-flex flex-column flex-fill justify-content-between my-auto">
										<div className="d-flex justify-content-between">
											<div className="font-weight-bold">
												{chat.userName}
											</div>
											<div className="text-muted small">{ CHAT_LIKE_TIME(chat.created_at) }</div>
										</div>
										<div className="d-flex justify-content-between">
											<div className="text-muted">
												{chat.type === 0 && <div>{chat.message.slice(0,20) }</div>}
												{chat.type === 1 && <div>icon here Photo</div>}
												{chat.type === 2 && <div>icon here Video</div>}
											</div>
											{ chat.unread_count < 1?
												<span className="small"></span>:
												<span className="bg-success d-inline rounded-pill px-1 ml-1 my-auto text-white small">
													{ chat.unread_count }
												</span> 
											}
										</div>
									</div>
								</div>
							)}
							{fetching_more_chats &&
								<div className='d-flex justify-content-center'>
									<div className='w3-spin spinner w3-circle mt-2'></div>
								</div>
							}
						</div>:
						<div>
							No chat list found
						</div>
					}
				</div>
			}
		</div>
	</nav>)
}

const mapStateToProps=(state)=>{
	return{
		admin: state.admin,
		chats: state.initialPrivateChats,
		markSelectedChat: state.markSelectedChat,
	}
}

const mapDispatchToProps=(dispatch)=>{
	return{
		loadChats:(payload)=>dispatch(loadPrivateChats(payload))

	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatList);
